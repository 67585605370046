import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select} from 'antd';
import {labels} from '../../views/Labels';
import {SettingOutlined, PlusOutlined} from '@ant-design/icons';
import {api} from "../../services/service";
import {useSelector} from "react-redux";
import util from "../../utils";

const { TextArea } = Input;
const {Option} = Select;

const HouseFormInDrawer = ({title, elm, onSearch}) => {
	const [houseId, setHouseId] = React.useState();
	const [houseName, setHouseName] = React.useState();
	const [branchId, setBranchId] = React.useState();
	const [branchName, setBranchName] = React.useState();
	const [jextra, setJextra] = React.useState({});
	const [busStopId, setBusStopId] = React.useState();
	const [busDirection, setBusDirection] = React.useState();
	const [info, setInfo] = React.useState();
	const [isactivated, setIsactivated] = React.useState('N');
	const [branchList, setBranchList] = React.useState([]);
	const [branchOptions, setBranchOptions] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const wsData = useSelector(state => state.ws);
	const [buttonSize, setButtonSize] = React.useState(title === labels.HOUSE_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	const onSearchBranch = async () => {
		await api.getBranchList();
	};

	React.useEffect(() => {
		const branchList = wsData.branchList;
		if(branchList) {
			setBranchList(branchList);
			let list = [];
			for (let key of branchList) {
				list.push({label: key['branch_name'], value: key['branch_id']});
			}
			setBranchOptions(list);
		}
	}, [wsData]);

	const showDrawer = (elm) => {
		onSearchBranch().catch();
		resetFields();
		setVisible(true);
		if (elm) {
			const {house_id: houseId, house_name: houseName, branch_id: branchId, branch_name: branchName, jextra = {}, remark, isactivated = false} = elm;
			setHouseId(houseId);
			setHouseName(houseName);
			setBranchId(branchId);
			setBranchName(branchName);
			setJextra(jextra);
			setBusStopId(jextra?.busStopId);
			setBusDirection(jextra?.busDirection);
			setInfo(remark);
			setIsactivated(isactivated);
			setInputData({houseId, houseName, branchId, branchName, jextra, info: remark, isactivated});
		}
	};

	const onClose = () => {
		setVisible(false);
		resetFields();
	};

	const onSubmit = async () => {
		if(inputData) {
			if(branchName === undefined) {
				return util.showWarning('브랜치 이름을 선택해주세요.');
			}
			if (inputData["houseName"] !== undefined && busStopId !== undefined && busDirection !== undefined) {
				let dataInfo;
				if(busStopId && busDirection) {
					dataInfo = {...inputData, jextra: {busStopId, busDirection}};
				} else {
					dataInfo = {...inputData};
				}
				setVisible(false);
				await api.houseCUD(dataInfo).then(res => {
					if(res) {
						onSearch();
						resetFields();
						util.showSuccess(labels.SUCCESS_MESSAGE);
					}
				});
			}
		}
	};

	const resetFields = () => {
		form.resetFields();
		setHouseId(undefined);
		setHouseName(undefined);
		setBranchId(undefined);
		setBranchName(undefined);
		setBusStopId(undefined);
		setBusDirection(undefined);
		setIsactivated(undefined);
		setBranchList([]);
		setBranchOptions([]);
		setInputData(undefined);
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;
		if(name === 'busStopId') {
			setBusStopId(value);
			return;
		}
		if(name === 'busDirection') {
			setBusDirection(value);
			return;
		}
		setInputData({...inputData, [name]: value});
	};

	const onBranchChange = (value, e) => {
		setBranchId(value);
		setBranchName(e.children);
		setInputData({...inputData, branchId: value, branchName: e.children});
	};

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
		setInputData({...inputData, isactivated: value});
	};

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
			        icon={title === labels.HOUSE_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.HOUSE_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" form={form} onFinish={onSubmit}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="houseName"
								label="정류장 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={houseName} placeholder="정류장 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="branchName"
								label="브랜치 이름"
							>
								<Select required={true} defaultValue={branchName} onChange={onBranchChange} placeholder="브랜치 이름을 선택하세요">
									{branchOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.value}>{type.label}</Option>
										)
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="busStopId"
								label="버스정류장번호"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={busStopId} placeholder="버스정류장번호를 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="busDirection"
								label="운행방향"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={busDirection} placeholder="운행방향을 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12} style={{paddingLeft: 20}}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
					</Row>
          <Row gutter={16}>
            <Col span={24}>
							<Form.Item
								name="info"
								label="정보"
								getValueFromEvent={e => handleInputData(e)}
							>
								<TextArea rows={10} defaultValue={info} />
							</Form.Item>
            </Col>
          </Row>
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '50px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button htmlType="submit" type="primary">
							{title === labels.HOUSE_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default HouseFormInDrawer;
