import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select} from 'antd';
import {FilterFilled, FilterOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import {pagination, protocolOption} from "../../utils/helper";
import {useSelector} from "react-redux";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import {ManagerHouseList} from "../../redux/actions/WS";

const {Option} = Select;

const ListFilterFormInDrawer = ({title}) => {
	const [houseName, setHouseName] = React.useState();
	const [postalCode, setPostalCode] = React.useState();
	const [protocol, setProtocol] = React.useState();
	const [deviceTypeName, setDeviceTypeName] = React.useState();
	const [deviceTypeOptions, setDeviceTypeOptions] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({});
	const wsData = useSelector(state => state.ws);
	const [form] = Form.useForm();

	React.useEffect(() => {
		pagination["searchWord"] = '';
		setTimeout(() => {
			api.wsGetDeviceTypeList();
		}, 0);
	}, [])

	React.useEffect(() => {
		console.log(inputData)
	}, [inputData])

	React.useEffect(() => {
		let deviceTypeList = [];
		for (let key of wsData.deviceTypeList) {
			deviceTypeList.push({label: key.device_type_name, value: key.device_type_name});
		}
		setDeviceTypeOptions(deviceTypeList);
	}, [wsData])

	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
		// form.resetFields();
	};

	const onSubmit = () => {
		let inputDataCount = 0;
		for(let key in inputData){
			if(inputData[key] !== ''){
				inputDataCount++
			}
		}
		store.dispatch(filterBadgeCounter(inputDataCount));
		setVisible(false);

		// if (inputData['houseName'] === undefined || inputData['postalCode'] === undefined || inputData['houseType'] === undefined || inputData['address'] === undefined) {
		// 	console.log('!!!!!!!!!!!!!!!', inputData)
		// 	// let validatorObj = {};
		// 	// for (let key in inputData) {
		// 	// 	console.log(key)
		// 	// 	console.log(validatorObj)
		// 	// 	if (inputData[key] === undefined) {
		// 	// 		validatorObj[key] = true;
		// 	// 		// setValidator({...validator, [key]: true})
		// 	// 	} else {
		// 	// 		validatorObj[key] = false;
		// 	// 	}
		// 	// }
		// 	// setValidator(validatorObj);
		// } else {
		// 	setVisible(false);
		// 	// if (title === labels.HOUSE_ADD) {
		// 	// 	api.wsHouseCUD(inputData);
		// 	// 	form.resetFields();
		// 	// } else if (title === labels.HOUSE_EDIT) {
		// 	// 	api.wsHouseEdit(inputData);
		// 	// 	form.resetFields();
		// 	// }
		// }
	}

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;

		setInputData({...inputData, [name]: value});
	}

	const handleDeviceTypeSelect = e => {
		const name = 'deviceType';
		const value = e;

		setInputData({...inputData, [name]: value});
	}

	const handleProtocolSelect = e => {
		const name = 'protocol';
		const value = e;

		setInputData({...inputData, [name]: value});
	}

	return (
		<div>
			<Button type="ghost"
			        style={{marginLeft: '10px'}}
			        icon={<FilterFilled/>}
			        onClick={() => showDrawer()}>
				{title === labels.HOUSE_EDIT ? undefined : title}

				{/*<Button style={{marginLeft: '10px'}} icon={<FilterFilled />}/>*/}
			</Button>
			<Drawer
				title={title}
				width={720}
				onClose={() => onClose()}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
				placement={'left'}
			>
				<Form layout="vertical" form={form}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="houseName"
								label="하우스 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={houseName} placeholder="하우스 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="deviceName"
								label="디바이스 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={postalCode} placeholder="디바이스 이름을 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="deviceType"
								label='디바이스 타입'
								getValueFromEvent={e => handleDeviceTypeSelect(e)}
							>
								<Select defaultValue={""} placeholder="디바이스 타입을 선택하세요">
									<Option value={""}>All</Option>
									{deviceTypeOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="serial"
								label="시리얼 넘버"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={postalCode} placeholder="시리얼 넘버를 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="protocol"
								label="프로토콜"
								getValueFromEvent={e => handleProtocolSelect(e)}
							>
								<Select defaultValue={""} placeholder="프로토콜을 선택하세요">
									<Option value={""}>All</Option>
									{protocolOption?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							textAlign: 'center', marginTop: '20px'
						}}
					>
						<Button
							onClick={() => onClose()}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} type="primary">
							검색
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default ListFilterFormInDrawer;
