import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select} from 'antd';
import {labels} from '../../views/Labels';
import {SettingOutlined, PlusOutlined} from '@ant-design/icons';
import {api} from "../../services/service";
import util from "../../utils";
import {useSelector} from "react-redux";

const { TextArea } = Input;
const {Option} = Select;

const BranchFormInDrawer = ({title, elm, onSearch, showSuccess}) => {
	const [branchId, setBranchId] = React.useState();
	const [branchName, setBranchName] = React.useState();
	const [branchType, setBranchType] = React.useState();
	const [branchTypeList, setBranchTypeList] = React.useState();
	const [info, setInfo] = React.useState();
	const [isactivated, setIsactivated] = React.useState(false);
	const [branchTypeOptions, setBranchTypeOptions] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const wsData = useSelector(state => state.ws);
	const [buttonSize, setButtonSize] = React.useState(title === labels.BRANCH_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	React.useEffect(() => {
		const branchTypeList = wsData.branchTypeList;
		if(branchTypeList) {
			setBranchTypeList(branchTypeList);
			let typeList = [];
			for (let key of branchTypeList) {
				typeList.push({label: key, value: key});
			}
			setBranchTypeOptions(typeList);
		}
	}, [wsData]);

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		if (elm) {
			const {branch_id: branchId, branch_name: branchName, branch_type: branchType, info, isactivated} = elm;
			setBranchId(branchId);
			setBranchName(branchName);
			setBranchType(branchType);
			setInfo(info);
			setIsactivated(isactivated);
			setInputData({...inputData, branchId, branchName, branchType, info, isactivated});
		}
	};

	const onClose = () => {
		setVisible(false);
		form.resetFields();
	};

	const onSubmit = async () => {
		if(inputData) {
			if(branchType === undefined) {
				return util.showWarning('브랜치 타입을 선택해주세요.');
			}
			if (inputData["branchName"] !== undefined) {
				setVisible(false);
				await api.branchCUD(inputData).then((res, errMessage) => {
					if(res) {
						onSearch();
						resetFields();
						util.showSuccess(labels.SUCCESS_MESSAGE);
					}
				});
			}
		}
	};

	const resetFields = () => {
		form.resetFields();
		setBranchId(undefined);
		setBranchName(undefined);
		setBranchType(undefined);
		setInfo(undefined);
		setIsactivated(false)
		setInputData(undefined);
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;

		setInputData({...inputData, [name]: value});
	};

	const handleSelectData = (value, name) => {
		setBranchType(value);
		setInputData({...inputData, [name]: value});
	}

	const handleUserActivativeSwitch = (value, name) => {
		setIsactivated(value);
		setInputData({...inputData, [name]: value});
	};

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
			        icon={title === labels.BRANCH_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.BRANCH_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="branchName"
								label="브랜치 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={branchName} required={true} placeholder="브랜치 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="branchType"
								label="브랜치 타입"
								getValueFromEvent={e => handleSelectData(e, 'branchType')}
							>
								<Select defaultValue={branchType} required={true} placeholder="브랜치 타입을 선택하세요">
									{branchTypeOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12} style={{paddingLeft: 20}}>
							<Form.Item
								name="isactive"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e, 'isactivated')}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
					</Row>
          <Row gutter={16}>
            <Col span={24}>
							<Form.Item
								name="info"
								label="정보"
								getValueFromEvent={e => handleInputData(e)}
							>
								<TextArea rows={10} defaultValue={info} />
							</Form.Item>
            </Col>
          </Row>
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '50px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} htmlType={"submit"} type="primary">
							{title === labels.BRANCH_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default BranchFormInDrawer;
