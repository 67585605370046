import React from "react";
import {LogoutOutlined} from '@ant-design/icons';
import {api} from '../../services/service'

export const NavLogout = () => {
	const onLogout = () => {
	  api.logout().catch();
  };
	return (
    <div style={{cursor: 'pointer', paddingRight: 20}} onClick={onLogout}>
      <LogoutOutlined className="mr-2"/><span className="font-weight-normal">로그아웃</span>
    </div>
	);
}

export default NavLogout;
