import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Table, Tooltip, Tag, Popconfirm} from 'antd';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import {scopeOptions} from "../../utils/helper";
import HouseSelectInDrawer from "./HouseSelectInDrawer";
import {useSelector} from "react-redux";
import SmartRoutineJtriggerFormInDrawer from "./SmartRoutineJtriggerFormInDrawer";
import SmartRoutineJactionFormInDrawer from "./SmartRoutineJactionFormInDrawer.js";
import util from "../../utils";

const SmartRoutineFormInDrawer = ({title, elm, onSearch}) => {
	const [houseId, setHouseId] = React.useState();
	const [houseName, setHouseName] = React.useState();
	const [busDirection, setBusDirection] = React.useState();
	const [selectDeviceHouse, setSelectDeviceHouse] = React.useState();
	const [routineId, setRoutineId] = React.useState();
	const [routineName, setRoutineName] = React.useState();
	const [isactivated, setIsactivated] = React.useState(false);
	const [jtrigger, setJtrigger] = React.useState([]);
	const [jaction, setJaction] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const [houseList, setHouseList] = React.useState([]);
	const [houseListTotal, setHouseListTotal] = React.useState(0);
	const wsData = useSelector(state => state.ws);
	const [buttonSize, setButtonSize] = React.useState(title === labels.SMART_ROUTINE_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	React.useEffect(() => {
		setHouseList(wsData.houseList)
		setHouseListTotal(wsData.houseListTotal);
	}, [wsData]);

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		let jtriggerInfo = [], jactionInfo = [];
		if (elm) {
			const {house_id, house_name, bus_direction, routine_id, routine_name, isactivated, jtrigger, jaction} = elm;
			if (jtrigger && jtrigger.length > 0) {
				jtriggerInfo = jtrigger?.map((trigger, i) => {
					return (
						{...trigger, id: i + 1}
					);
				});
			}
			if (jaction && jaction.length > 0) {
				jactionInfo = jaction?.map((action, i) => {
					return (
						{...action, id: i + 1}
					);
				});
			}
			setHouseId(house_id);
			setHouseName(house_name);
			setBusDirection(bus_direction);
			setRoutineId(routine_id);
			setRoutineName(routine_name);
			setIsactivated(isactivated);
			setJtrigger(jtriggerInfo);
			setJaction(jactionInfo);
			setInputData({
				...inputData,
				houseId: house_id,
				houseName: house_name,
				busDirection: bus_direction,
				routineId: routine_id,
				routineName: routine_name,
				isactivated: isactivated,
				jtrigger: jtriggerInfo,
				jaction: jactionInfo
			});
		}
	};

	const onClose = () => {
		setVisible(false);
		resetFields();
	};

	const onSubmit = async () => {
		if(inputData) {
			if(inputData['houseId'] === undefined) {
				return util.showWarning('하우스를 선택해주세요.');
			}
			if (inputData['routineName'] !== undefined) {
				setVisible(false);
				await api.smartRoutineCUD({...inputData, isactivated}).then(res => {
					if(res) {
						onSearch();
						resetFields();
						util.showSuccess(labels.SUCCESS_MESSAGE);
					}
				});
			}
		}
	};

	const resetFields = () => {
		form.resetFields();
		setHouseId(undefined);
		setHouseName(undefined);
		setBusDirection(undefined);
		setRoutineId(undefined);
		setRoutineName(undefined);
		setIsactivated(false);
		setJtrigger([]);
		setJaction([]);
		setInputData(undefined);
	};

	const getSelectedHouseData = (data) => {
		if(data && data[0]) {
			const houseId = data[0].house_id;
			const houseName = data[0].house_name;
			const busDirection = data[0].bus_direction;
			setHouseId(houseId);
			setHouseName(houseName);
			setBusDirection(busDirection);
			setSelectDeviceHouse(data[0]);
			setInputData({...inputData, houseId, busDirection});
			form.setFieldsValue({houseName: houseName});
			form.setFieldsValue({busDirection: busDirection});
		}
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;
		setInputData({...inputData, [name]: value});
	};

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
		setInputData({...inputData, isactivated: value});
	};

	const deleteJtrigger = elm => {
		let jtriggerData = jtrigger;
		let index = -1;

		for (let key of jtriggerData) {
			index++;
			if (key["deviceName"] === elm["deviceName"] && key["data"] === elm["data"] && key["value"] === elm["value"] &&
				key["time"] === elm["time"]) {
				jtriggerData.splice(index, 1)
			}
		}
		setJtrigger(jtriggerData);
		setInputData({...inputData, jtrigger: jtriggerData});
		form.resetFields();
	};

	const deleteJaction = elm => {
		let jactionData = jaction;
		let index = -1;

		for (let key of jactionData) {
			index++;
			if (key["deviceName"] === elm["deviceName"] && key["commandName"] === elm["commandName"] && key["commandValue"] === elm["commandValue"]) {
				jactionData.splice(index, 1)
			}
		}

		setJaction(jactionData)
		setInputData({...inputData, jaction: jactionData});
		form.resetFields();
	};

	const getJtriggerData = (data) => {
		if (data.id) {
			jtrigger.map((trigger) => {
				if (trigger.id === data.id) {
					trigger.id = data.id;
					trigger.deviceId = data.deviceId;
					trigger.deviceName = data.deviceName;
					trigger.deviceTypeId = data.deviceTypeId;
					trigger.sensorType = data.sensorType;
					trigger.value = data.value;
					trigger.scope = data.scope;
					trigger.time = data.time;
					trigger.fromTime = data.fromTime;
					trigger.toTime = data.toTime;
					trigger.isactivated = data.isactivated;
				}
			})
			setJtrigger([...jtrigger]);
			setInputData({...inputData, jtrigger: [...jtrigger]});
		} else {
			const dataInfo = {...data, id: jtrigger.length + 1};
			setJtrigger([...jtrigger, dataInfo]);
			setInputData({...inputData, jtrigger: [...jtrigger, data]});
		}
	};
	const getJactionData = (data) => {
		if (data.id) {
			jaction.map((action) => {
				if (action.id === data.id) {
					action.id = data.id;
					action.deviceId = data.deviceId;
					action.deviceName = data.deviceName;
					action.deviceTypeId = data.deviceTypeId;
					action.typeName = data.typeName;
					action.type = data.type;
					action.commandType = data.commandType;
					action.commandValue = data.commandValue;
					action.isactivated = data.isactivated;
				}
			})
			setJaction([...jaction]);
			setInputData({...inputData, jaction: [...jaction]});
		} else {
			const dataInfo = {...data, id: jaction.length + 1};
			setJaction([...jaction, dataInfo]);
			setInputData({...inputData, jaction: [...jaction, data]});
		}
	};

	const triggerTableColumns = [
		{
			title: '디바이스 이름',
			dataIndex: 'deviceName',
			width: 160,
		},
		{
			title: '항목',
			dataIndex: 'sensorType',
			width: 130,
		},
		{
			title: '값',
			dataIndex: 'value',
			width: 60,
		},
		{
			title: '범위',
			dataIndex: 'scope',
			width: 80,
			render: (_, record) => (
				<div className="d-flex">{scopeOptions.filter(x => record['scope'] === x.value).map(c => c.label)}</div>
			),
		},
		{
			title: '시간설정',
			dataIndex: 'time',
			width: 130,
			render: (_, record) => (
				<div className="d-flex">{record['fromTime']} ~ {record['toTime']}</div>
			)
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 90,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			)
		},
		{
			title: '',
			dataIndex: 'actions',
			width: 120,
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="View">
						<SmartRoutineJtriggerFormInDrawer title={labels.SMART_ROUTINE_JTRIGGER_EDIT} elm={elm} houseId={houseId} getJtriggerData={getJtriggerData}/>
					</Tooltip>
					<Tooltip title="Delete">
						<div style={{float: 'left'}}>
							<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
								deleteJtrigger(elm)
							}} okText={labels.YES} cancelText={labels.NO}>
								<Button danger icon={<DeleteOutlined/>} size="small"/>
							</Popconfirm>
						</div>
					</Tooltip>
				</div>
			)
		}
	];

	const actionTableColumns = [
		{
			title: '디바이스 이름',
			dataIndex: 'deviceName',
			width: 160,
		},
		{
			title: '명령항목',
			dataIndex: 'typeName',
			width: 130,
		},
		{
			title: '실행 값',
			dataIndex: 'commandType',
			width: 100,
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 100,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			width: 120,
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title="View">
						<SmartRoutineJactionFormInDrawer title={labels.SMART_ROUTINE_JACTION_EDIT} elm={elm} houseId={houseId} getJactionData={getJactionData}/>
					</Tooltip>
					<Tooltip title="Delete">
						<div style={{float: 'left'}}>
							<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
								deleteJaction(elm)
							}} okText={labels.YES} cancelText={labels.NO}>
								<Button danger icon={<DeleteOutlined/>} size="small"/>
							</Popconfirm>
						</div>
					</Tooltip>
				</div>
			)
		}
	];

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
			        icon={title === labels.SMART_ROUTINE_EDIT ?
				        <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.SMART_ROUTINE_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={10}>
							<Form.Item
								name="houseName"
								label="정류장 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} disabled={true} defaultValue={houseName} placeholder='정류장 이름을 선택하세요'/>
							</Form.Item>
						</Col>
						<Col span={2} style={{marginTop: '29px'}}>
							<HouseSelectInDrawer title={title} elm={elm} getSelectedHouseData={getSelectedHouseData}/>
						</Col>
						<Col span={12} style={{paddingLeft: '10px'}}>
							<Form.Item
								name="busDirection"
								label="운행방향"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} disabled={true} defaultValue={busDirection} placeholder="운행방향을 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12} style={{paddingLeft: '10px'}}>
							<Form.Item
								name="routineName"
								label="스마트 루틴 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={routineName} placeholder="루틴 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12} style={{paddingLeft: '10px'}}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="jtrigger"
								label="상황(트리거)"
								initialValue={jtrigger}
								getValueFromEvent={e => handleInputData(e)}
							>
								<Table columns={triggerTableColumns} dataSource={jtrigger} rowKey='id' bordered pagination={false} scroll={{ x: 'max-content', y: 210 }}/>
								<SmartRoutineJtriggerFormInDrawer title={labels.SMART_ROUTINE_JTRIGGER_ADD} houseId={houseId} getJtriggerData={getJtriggerData}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="jaction"
								label="액션(실행)"
								initialValue={jaction}
								getValueFromEvent={e => handleInputData(e)}
							>
								<Table columns={actionTableColumns} dataSource={jaction} rowKey='id' bordered pagination={false} scroll={{ x: 'max-content', y: 210 }}/>
								<SmartRoutineJactionFormInDrawer title={labels.SMART_ROUTINE_JACTION_ADD} houseId={houseId} getJactionData={getJactionData}/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '80px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} htmlType={"submit"} type="primary">
							{title === labels.SMART_ROUTINE_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default SmartRoutineFormInDrawer;
