import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select, Modal} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import DeviceSelectInDrawer from "./DeviceSelectInDrawer";
import {scopeOptions} from "../../utils/helper";
import {useSelector} from "react-redux";
import {api} from "../../services/service";
import util from "../../utils";

const {Option} = Select;

const SmartRoutineJtriggerFormInDrawer = ({title, elm, houseId, getJtriggerData}) => {
	const [id, setId] = React.useState(0);
	const [deviceTypeId, setDeviceTypeId] = React.useState();
	const [deviceId, setDeviceId] = React.useState();
	const [deviceName, setDeviceName] = React.useState();
	const [sensorType, setSensorType] = React.useState();
	const [value, setValue] = React.useState();
	const [scope, setScope] = React.useState('over');
	const [fromTime, setFromTime] = React.useState();
	const [toTime, setToTime] = React.useState();
	const [visible, setVisible] = React.useState(false);
	const [isactivated, setIsactivated] = React.useState(false);
	const [sensorTypeOptions, setSensorTypeOptions] = React.useState([]);
	const scopeRef = React.useRef(scope);
	const wsData = useSelector(state => state.ws);
	const [form] = Form.useForm();
	const [modal, contextHolder] = Modal.useModal();

	React.useEffect(() => {
		form.resetFields(['sensorType']);
		setSensorTypeOptions([]);
		const deviceTypeList = wsData.deviceTypeList;
		if(deviceTypeList?.length > 0) {
			if(deviceTypeList[0]?.jsensor?.length > 0) {
				const sensorType = deviceTypeList[0].jsensor.map((data) => {
					return ({
							label: data.sensorType,
							value: data.sensorType
						}
					);
				});
				setSensorTypeOptions(sensorType);
			}
		}
	}, [wsData]);

	const onSearchSensorType = async (id) => {
		await api.getDeviceTypeList({deviceTypeId: id});
	};

	const showDrawer = (elm) => {
		resetFields();
		if(!houseId) {
			// return modal.warning({title: '하우스를 선택해 주세요.'});
			return util.showWarning('하우스를 선택해 주세요.');
		}
		setVisible(true);
		if(elm) {
			console.log('hello elm=>', elm);
			const {id, deviceId, deviceName, deviceTypeId, sensorType, value, scope, fromTime, toTime, isactivated} = elm;
			setId(id);
			setDeviceId(deviceId);
			setDeviceName(deviceName);
			setDeviceTypeId(deviceTypeId);
			setSensorType(sensorType);
			setValue(value);
			setScope(scope);
			setFromTime(fromTime);
			setToTime(toTime);
			setIsactivated(isactivated);
			form.setFieldsValue(elm);
			scopeRef.current = scope;
			if(deviceTypeId) {
				onSearchSensorType(deviceTypeId).catch();
			}
		}
	};

	const onClose = () => {
		setVisible(false);
		form.resetFields();
		resetFields();
	};

	const onSubmit = (values) => {
		if(values.deviceName || values.fromTime) {
			const value = values.value ? parseFloat(values.value) : undefined;
			const fromTime = values.fromTime ? parseInt(values.fromTime) : undefined;
			const toTime = values.toTime ? parseInt(values.toTime) : undefined;
			const dataInfo = {...values, id, deviceId, deviceTypeId, sensorType, scope: values.scope??scope, value, fromTime, toTime, isactivated};
			setVisible(false);
			getJtriggerData(dataInfo);
			form.resetFields();
			resetFields();
		} else {
			return util.showWarning(`'디바이스 이름' 또는 '시작시간'을 작성해주세요.`);
		}
	};

	const resetFields = () => {
		form.resetFields(['sensorType']);
		form.resetFields(['scope']);
		setSensorTypeOptions([]);
		setDeviceId(undefined);
		setDeviceName(undefined);
		setDeviceTypeId(undefined);
		setSensorType(undefined);
		setValue(undefined);
		setScope('over');
		setFromTime(undefined);
		setToTime(undefined);
		setIsactivated(false);
	};

	const getSelectedDeviceData = (data) => {
		if(data && data[0]) {
			const deviceId = data[0].device_id;
			const deviceName = data[0].device_name;
			const deviceTypeId = data[0].device_type_id;
			setDeviceId(deviceId);
			setDeviceName(deviceName);
			setDeviceTypeId(deviceTypeId);
			form.setFieldsValue({deviceName: deviceName});
			onSearchSensorType(deviceTypeId).catch();
		}
	};

	const onChangeSensorType = (value) => {
		setSensorType(value);
	};

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
	};

	const buttonStyle = title === labels.SMART_ROUTINE_JTRIGGER_EDIT ?
		{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'} :
		{display: 'flex', justifyContent: 'flex-end', marginTop: '12px', marginRight: '8px'};

	return (
		<div style={buttonStyle}>
			<Button type="primary"
			        icon={title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? <SettingOutlined/> :
				        <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={'small'}>
				{title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={16}>
						<Col span={10}>
							<Form.Item
								name="deviceName"
								label="디바이스 이름"
							>
								<Input disabled={true} defaultValue={deviceName} placeholder='디바이스 이름을 선택하세요'/>
							</Form.Item>
						</Col>
						<Col span={2} style={{marginTop: '29px'}}>
							<DeviceSelectInDrawer elm={elm} houseId={houseId} getSelectedDeviceData={getSelectedDeviceData}/>
						</Col>
            <Col span={12}>
							<Form.Item
								name="sensorType"
								label="항목"
							>
								<Select defaultValue={sensorType} placeholder="항목을 선택하세요" onChange={onChangeSensorType}>
									{sensorTypeOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="value"
                label="값"
              >
                <Input type={'number'} defaultValue={value} placeholder="값을 작성하세요"/>
              </Form.Item>
            </Col>
            <Col span={12}>
							<Form.Item
								name="scope"
								label="범위"
							>
								<Select defaultValue={scope} placeholder="범위를 선택하세요">
									{scopeOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="fromTime"
                label="시간설정"
								// rules={[{ required: true, message: '시작시간을 설정해주세요.' }]}
              >
                <Input type={'number'} defaultValue={fromTime} placeholder="시작시간"/>
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: '36px', paddingLeft: '25px', paddingRight: '25px'}}>~</Col>
            <Col span={6}>
              <Form.Item
                name="toTime"
                label=" "
              >
                <Input type={'number'} defaultValue={toTime} placeholder="종료시간"/>
              </Form.Item>
            </Col>
						<Col span={6} style={{paddingLeft: 20}}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
          </Row>
          <Form.Item
            style={{
              textAlign: 'center',
              marginTop: '50px'
            }}
          >
            <Button
              onClick={() => onClose(elm)}
              style={{marginRight: 8}}
            >
              취소
            </Button>
            <Button htmlType="submit" type="primary">
              {title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? '수정' : '추가'}
            </Button>
          </Form.Item>
        </Form>
			</Drawer>
			{contextHolder}
		</div>
	);
}

export default SmartRoutineJtriggerFormInDrawer;
