import axios from 'axios';
import webSocket from '../services/webSocket'
import history from "../history";
import {store} from "../App";
import {
	UserData,
	UserList,
	UserListTotal,
	BranchList,
	BranchListTotal,
	BranchTypeList,
	HouseList,
	HouseListTotal,
	DeviceTypeList,
	DeviceTypeListTotal,
	DeviceList,
	DeviceListTotal,
	SmartRoutineList,
	SmartRoutineListTotal,
	DeviceViewList,
	DeviceViewListTotal,
	DeviceDataList,
	DeviceDataListTotal,
	DidInfoListTotal,
	DidInfoList,
} from "../redux/actions/WS";
import util from "../utils";
import {labels} from "../views/Labels";

const useDev = window.location.host === 'localhost:3000' || window.location.host === 'localhost:3001';

const API_URL_DEV = 'http://goyang.uplink.plus';
// const API_URL_PRD = useDev ? API_URL_DEV : 'https://gy.uplink.plus';
const API_URL_PRD = useDev ? API_URL_DEV : window.location.protocol;

const API_URL = process.env.NODE_ENV === 'production' ? API_URL_PRD : API_URL_DEV;
const DEFAULT_URL = '/admin/dashboards/default';

function showDialog() {
}

class API {
	token;
	dispatch;
	history;

	async login(data) {
		const action = 'LogIn';
		// const timeDelta = new Date().getTimezoneOffset();
		const reqData = {action, data};
		const {data: res} = await this.sendList('user/R', reqData);
		console.log('[Login info]', res)

		// const res = [{id: 1, email: 'test@uplink.plus', token: 'asdlfaskldf', key_str: 'alkajsdf'}]
		if (res) {
			const {id, email, key_str} = res[0];
			this.setUserAndToken(res[0]);
			return {id, email, key_str};
		} else {
			return null;
		}
	}

	getToken() {
		return this.token ?? localStorage.getItem('auth_token');
	}

	getUserInfo() {
		return JSON.parse(localStorage.getItem('user_info'));
	}

	setUserAndToken(user) {
		const {token} = user;
		this.token = token;

		localStorage.setItem('auth_token', token);
		localStorage.setItem('user_info', JSON.stringify(user));
		document.location.href = DEFAULT_URL;
		// updateUser(this.dispatch, {...user, ready: true});
	}

	async logout() {
		this.token = undefined;
		localStorage.removeItem('auth_token');
		localStorage.removeItem('user_info');
		history.go(0);
	}

	async sendList(path, reqData, doNotShowError) {
		const res = await this.send(path, reqData, doNotShowError);
		return res ?? {data: null, totalCount: 0};
	}

	async send(path, reqData, doNotShowError) {
		try {
			//process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, reqData);
			const res = await axios.request({
				url: `${API_URL}/${path}`,
				method: 'POST',
				data: reqData,
				headers: {
					Authorization: `bearer ${this.getToken()}`,
				}
			});
			process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);
			const {err_message: message, data, totalcount: totalCount = 0} = res.data;
			if (!message || typeof message === "number") {
				return {data, totalCount};
			} else {
				// const msg = message.toLowerCase();
				if (message === labels.ERROR999) {
					// sessionStorage.clear();
					await this.logout();
					document.location.href = '/admin/login';
				}
				else {
					if (message) {
						util.showError(message);
						return console.log('[ERROR] : ', message);
					}
				}
			}
		} catch (error) {
			if (error?.isAxiosError && error?.response?.status === 401) {
				document.location.href = '/admin/login';
			} else {
				if (doNotShowError !== true) {
					util.showError(error);
				}
			}
		}
	}

	async getUserData(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetUserData';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(UserData(res?.data));
		})
	}

	async getUserList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetUserList';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(UserList(res?.data));
			store.dispatch(UserListTotal(res?.totalCount));
		})
	}

	async userCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetUser';
		const reqData = {action, token, data};
		return this.send('user/CUD', reqData).then(res => {
			return res;
		})
	}

	async getBranchList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetBranchList';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(BranchList(res?.data));
			store.dispatch(BranchListTotal(res?.totalCount));
		})
	}

	async branchCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetBranch';
		const reqData = {action, token, data};
		return this.send('user/CUD', reqData).then(res => {
			return res;
		})
	}

	async getBranchTypes(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetBranchTypes';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(BranchTypeList(res?.data));
		})
	}

	async getHouseList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetHouseList';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(HouseList(res?.data));
			store.dispatch(HouseListTotal(res?.totalCount));
		})
	}

	async getHouseArray(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetHouseArray';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(HouseList(res?.data));
			store.dispatch(HouseListTotal(res?.totalCount));
		})
	}

	async houseCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetHouse';
		const reqData = {action, token, data};
		return this.send('user/CUD', reqData).then(res => {
			return res;
		})
	}

	async getDeviceTypeList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDeviceTypeList';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DeviceTypeList(res?.data));
			store.dispatch(DeviceTypeListTotal(res?.totalCount));
		})
	}

	async getDeviceTypeArray(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDeviceTypeArray';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DeviceTypeList(res?.data));
			store.dispatch(DeviceTypeListTotal(res?.totalCount));
		})
	}

	async deviceTypeCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetDeviceType';
		const reqData = {action, token, data};
		return this.send('device/CUD', reqData).then(res => {
			return res;
		})
	}

	async getDeviceList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDeviceList';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DeviceList(res?.data));
			store.dispatch(DeviceListTotal(res?.totalCount));
		})
	}

	async deviceCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetDevice';
		const reqData = {action, token, data};
		return this.send('device/CUD', reqData).then(res => {
			return res;
		})
	}

	async getSmartRoutineList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetSmartRoutineList';
		const reqData = {action, token, data};
		this.send('user/R', reqData).then(res => {
			store.dispatch(SmartRoutineList(res?.data));
			store.dispatch(SmartRoutineListTotal(res?.totalCount));
		})
	}

	async smartRoutineCUD(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetSmartRoutine';
		const reqData = {action, token, data};
		return this.send('user/CUD', reqData).then(res => {
			return res;
		})
	}

	async getDeviceViewList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDeviceView';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DeviceViewList(res?.data));
			store.dispatch(DeviceViewListTotal(res?.totalCount));
		})
	}

	async getDeviceDataList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDeviceDataList';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DeviceDataList(res?.data));
			store.dispatch(DeviceDataListTotal(res?.totalCount));
		})
	}

	async setDeviceSendCmd(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SendCmd';
		const reqData = {action, token, data};
		this.send('device/CUD', reqData).then(res => {
			console.log(res)
			// store.dispatch(DeviceDataList(res?.data));
			// store.dispatch(DeviceDataListTotal(res?.totalCount));
		})
	}

	async getDidInfoList(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'GetDidInfoList';
		const reqData = {action, token, data};
		this.send('device/R', reqData).then(res => {
			store.dispatch(DidInfoList(res?.data));
			store.dispatch(DidInfoListTotal(res?.totalCount));
		})
	}

	async setDidInfo(data) {
		const {id, key_str, token} = this.getUserInfo();
		const action = 'SetDidInfo';
		const reqData = {action, token, data};
		return this.send('device/CUD', reqData).then(res => {
			return res;
		})
	}


	// TODO ------------------------------------ 웹 소켓 관련 ---------------------------------------------------
	// wsGetUserData() {
	// 	const {id, key_str, token} = this.getUserInfo();
	// 	// console.log('id', id)
	// 	webSocket({
	// 		url: `${WS_URL}/${key_str}`,
	// 		action: 'GetUserData',
	// 		id: Number(id),
	// 		token: token,
	// 		handler: 'user'
	// 	})
	// }
}

export default API;
