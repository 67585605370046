import React from 'react'
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from "react-css-theme-switcher";
import Router from "../../views/Router";

export const AuthLayout = () => {
	const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }
	return (
		<div className="auth-container">
			<Router/>
		</div>
	)
}


export default AuthLayout
