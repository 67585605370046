import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Table, Tooltip, Popconfirm} from 'antd';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import DeviceTypeSelectInDrawer from "./DeviceTypeSelectInDrawer";
import util from "../../utils";

const DeviceTypeFormInDrawer = ({title, elm, onSearch}) => {
	const [deviceTypeName, setDeviceTypeName] = React.useState();
	const [maker, setMaker] = React.useState();
	const [isactivated, setIsactivated] = React.useState(false);
	const [isoutside, setIsoutside] = React.useState(false);
	const [sensors, setSensors] = React.useState([]);
	const [commands, setCommands] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const [buttonSize, setButtonSize] = React.useState(title === labels.DEVICE_TYPE_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		let jsensorInfo = [], jcommandInfo = [];
		if (elm) {
			const {
				device_type_id: deviceTypeId,
				device_type_name: deviceTypeName,
				maker,
				isactivated,
				isoutside,
				jsensor = {},
				jcommand = {}
			} = elm;
			if (jsensor.length > 0) {
				jsensorInfo = jsensor?.map((sensor, i) => {
					return (
						{...sensor, id: i + 1}
					);
				});
			}
			if (jcommand.length > 0) {
				jcommandInfo = jcommand?.map((command, i) => {
					return (
						{...command, id: i + 1}
					);
				});
			}
			setDeviceTypeName(deviceTypeName);
			setMaker(maker);
			setIsactivated(isactivated);
			setIsoutside(isoutside);
			setSensors(jsensorInfo);
			setCommands(jcommandInfo);
			setInputData({...inputData, deviceTypeId, deviceTypeName, maker, isactivated, isoutside, jsensor, jcommand});
		}
	};

	const onClose = () => {
		setVisible(false);
		resetFields();
	};

	const onSubmit = async () => {
		if(inputData) {
			if (inputData["deviceTypeName"] !== undefined && inputData["maker"] !== undefined) {
				setVisible(false);
				await api.deviceTypeCUD(inputData).then(res => {
					if(res) {
						onSearch();
						resetFields();
						util.showSuccess(labels.SUCCESS_MESSAGE);
					}
				});
			}
		}
	}

	const resetFields = () => {
		form.resetFields();
		setDeviceTypeName(undefined);
		setMaker(undefined);
		setIsactivated(false);
		setIsoutside(false);
		setSensors([]);
		setCommands([]);
		setInputData(undefined);
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;
		setInputData({...inputData, [name]: value});
	}

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
		const name = 'isactivated';
		setInputData({...inputData, [name]: value});
	}

	const handleUserOutsideSwitch = value => {
		setIsoutside(value);
		const name = 'isoutside';
		setInputData({...inputData, [name]: value});
	}

	const deleteSensor = elm => {
		let sensorsData = sensors;
		let index = -1;

		for (let key of sensorsData) {
			index++;
			if (key["sensorType"] === elm["sensorType"] && key["sensorName"] === elm["sensorName"]) {
				sensorsData.splice(index, 1)
			}
		}
		setSensors(sensorsData);
		setInputData({...inputData, jsensor: sensorsData});
		form.resetFields();
	}

	const deleteCommand = elm => {
		let commandData = commands;
		let index = -1;

		for (let key of commandData) {
			index++;
			if (key["commandType"] === elm["commandType"] && key["commandName"] === elm["commandName"]) {
				commandData.splice(index, 1)
			}
		}

		setCommands(commandData)
		setInputData({...inputData, jcommand: commandData});
		form.resetFields();
	}

	const getSensorData = (data) => {
		if(data) {
			if (data.id) {
				sensors?.map((sensor) => {
					if (sensor.id === data.id) {
						sensor.sensorType = data.sensorType;
						sensor.sensorName = data.sensorName;
						sensor.sensorHigh = data.sensorHigh;
						sensor.sensorDefault = data.sensorDefault;
						sensor.sensorLow = data.sensorLow;
						sensor.sensorUnit = data.sensorUnit;
						sensor.sensorOffset = data.sensorOffset;
						sensor.sensorGline = data.sensorGline;
					}
				})
				setSensors([...sensors]);
				setInputData({...inputData, jsensor: [...sensors]});
			} else {
				const dataInfo = {id: sensors?.length + 1 ?? 0, ...data};
				setSensors([...sensors, dataInfo]);
				setInputData({...inputData, jsensor: [...sensors, data]});
			}
		}
	};

	const getCommandData = (data) => {
		if(data) {
			if (data.id) {
				commands?.map((command) => {
					if (command.id === data.id) {
						command.type = data.commandType;
						command.name = data.commandName;
						command.command = data.command;
					}
				})
				setCommands([...commands]);
				setInputData({...inputData, jcommand: [...commands]});
			} else {
				const dataInfo = {id: commands?.length + 1 ?? 0, type: data.commandType, name: data.commandName, command: data.command};
				setCommands([...commands, dataInfo]);
				setInputData({...inputData, jcommand: [...commands, dataInfo]});
			}
		}
	};

	const sensorTableColumns = [
		{
			title: '타입',
			dataIndex: 'sensorType',
			width: 130,
		},
		{
			title: '이름',
			dataIndex: 'sensorName',
			width: 130,
		},
		{
			title: '유닛',
			dataIndex: 'sensorUnit',
			width: 130,
		},
		{
			title: '좋음:보통:나쁨',
			dataIndex: 'sensorGline',
			width: 160,
			render: (_, record) => (
				<div className="d-flex">
					{record['sensorGline'] && `${record['sensorGline'][0]}, ${record['sensorGline'][1]}, ${record['sensorGline'][2]}`}
				</div>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			width: 120,
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title="View">
						<DeviceTypeSelectInDrawer title={labels.SENSOR_EDIT} elm={elm} getSensorData={getSensorData}/>
					</Tooltip>
					<Tooltip title="Delete">
						<Button danger icon={<DeleteOutlined/>} onClick={() => {
							deleteSensor(elm)
						}} size="small"/>
					</Tooltip>
				</div>
			)
		}
	];

	const commandTableColumns = [
		{
			title: '타입',
			dataIndex: 'type',
			width: 150,
		},
		{
			title: '이름',
			dataIndex: 'name',
			width: 150,
		},
		{
			title: '값',
			dataIndex: 'command',
			width: 300,
			render: (_, record) => (
				<div className="d-flex">
					{record['command']?.map((com, i) => {
						const isComma = record['command'].length-1 === i ? '' : ',';
						return (
							<div>"{com.type}": {com.value} {isComma}</div>
						);
					})}
				</div>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			width: 120,
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title="View">
						<DeviceTypeSelectInDrawer title={labels.COMMAND_EDIT} elm={elm} getCommandData={getCommandData}/>
					</Tooltip>
					<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
						deleteCommand(elm)
					}} okText={labels.YES} cancelText={labels.NO}>
						<Button danger icon={<DeleteOutlined/>} size="small"/>
					</Popconfirm>
				</div>
			)
		}
	];

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
			        icon={title === labels.DEVICE_TYPE_EDIT || title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ?
				        <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.DEVICE_TYPE_EDIT || title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="deviceTypeName"
								label="타입 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={deviceTypeName} placeholder="타입 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="maker"
								label="메이커"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} defaultValue={maker} placeholder="메이커를 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="isoutside"
								label="외부 설치"
								initialValue={isoutside}
								getValueFromEvent={e => handleUserOutsideSwitch(e)}
							>
								<Switch checked={isoutside}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="jsensor"
								label="센싱"
								initialValue={sensors}
								getValueFromEvent={e => handleInputData(e)}
							>
								<Table columns={sensorTableColumns} dataSource={sensors} rowKey='id' bordered pagination={false} scroll={{ x: 'max-content', y: 210 }} />
								<DeviceTypeSelectInDrawer title={labels.SENSOR_ADD} getSensorData={getSensorData}/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="jcommand"
								label="명령어"
								initialValue={commands}
								getValueFromEvent={e => handleInputData(e)}
							>
								<Table columns={commandTableColumns} dataSource={commands} rowKey='id' bordered pagination={false} scroll={{ x: 'max-content', y: 210 }}/>
								<DeviceTypeSelectInDrawer title={labels.COMMAND_ADD} getCommandData={getCommandData}/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '13px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} htmlType={"submit"} type="primary">
							{title === labels.DEVICE_TYPE_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default DeviceTypeFormInDrawer;
