import React, {Component} from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch} from 'antd';
import {EditOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import util from "../../utils";

const UserFormInDrawer = ({title, elm, onSearchUser}) => {
	const [email, setEmail] = React.useState();
	const [username, setUsername] = React.useState();
	const [cphone, setCphone] = React.useState();
	const [isactivated, setIsactivated] = React.useState(false);
	const [userid, setUserid] = React.useState();
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const [buttonSize, setButtonSize] = React.useState(title === labels.HOUSE_EDIT || title === labels.USER_EDIT || title === labels.ADMIN_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		if (elm) {
			const {email, user_id: userId, user_name: userName, cphone, isactivated} = elm;
			setEmail(email);
			setUserid(userId);
			setUsername(userName);
			setCphone(cphone);
			setIsactivated(isactivated);
			setInputData({...inputData, email, userId, userName, cphone, isactivated});
		}
	};

	const onClose = () => {
		setVisible(false);
		form.resetFields();
	};

	const onSubmit = async () => {
		if(inputData) {
			if(inputData["email"] !== undefined && inputData["userName"] !== undefined && inputData["cphone"] !== undefined){
				setVisible(false);
				await api.userCUD(inputData).then(res => {
					if(res) {
						onSearchUser();
						resetFields();
						util.showSuccess(labels.SUCCESS_MESSAGE);
					}
				});
			}
		}
	};

	const resetFields = () => {
		form.resetFields();
		setEmail(undefined);
		setUsername(undefined);
		setCphone(undefined);
		setUserid(undefined);
		setIsactivated(false)
		setInputData(undefined);
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;
		setInputData({...inputData, [name]: value});
	};

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
		setInputData({...inputData, isactivated: value});
	};

	return (
		<div style={title !== labels.ADMIN_EDIT ? {
			display: 'flex',
			justifyContent: 'flex-end',
			marginRight: '8px'
		} : undefined}>
			{title === labels.ADMIN_EDIT ? <span><EditOutlined className="mr-3"/><span className="font-weight-normal"
			                                                                           onClick={() => showDrawer(elm)}>{title}</span></span>
				:
				<Button type="primary"
				        icon={title === labels.USER_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
				        onClick={() => showDrawer(elm)} size={buttonSize}>{title === labels.USER_EDIT ? undefined : title}
				</Button>}
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="email"
								label="이메일"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={email} required={true} type={"email"} placeholder="이메일을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="passwd"
								label="비밀번호"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input.Password required={true} placeholder="비밀번호를 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="userName"
								label="유저 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={username} required={true} type={"text"} placeholder="유저 이름을 작성하세요"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="cphone"
								label="전화번호"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={cphone} required={true} type={'tel'} placeholder="전화번호를 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							textAlign: 'center',
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} htmlType={"submit"} type="primary">
							{title === labels.USER_EDIT || title === labels.ADMIN_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);
};

export default UserFormInDrawer;
