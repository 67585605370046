import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import DeviceSelectInDrawer from "./DeviceSelectInDrawer";
import {api} from "../../services/service";
import {useSelector} from "react-redux";
import util from "../../utils";

const {Option} = Select;

const SmartRoutineJactionFormInDrawer = ({title, elm, houseId, getJactionData}) => {
	const [id, setId] = React.useState(0);
	const [deviceTypeId, setDeviceTypeId] = React.useState();
	const [deviceId, setDeviceId] = React.useState();
	const [deviceName, setDeviceName] = React.useState();
	const [typeName, setTypeName] = React.useState();
	const [type, setType] = React.useState();
	const [commandType, setCommandType] = React.useState();
	const [commandValue, setCommandValue] = React.useState();
	const [jcommand, setJcommand] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [isactivated, setIsactivated] = React.useState(false);
	const [typeOptions, setTypeOptions] = React.useState([]);
	const [commandValueOptions, setCommandValueOptions] = React.useState([]);
	const wsData = useSelector(state => state.ws);
	const [form] = Form.useForm();

	React.useEffect(() => {
		form.resetFields(['commandType']);
		form.resetFields(['commandValue']);
		setTypeOptions([]);
		const deviceTypeList = wsData.deviceTypeList;
		if(deviceTypeList?.length > 0) {
			onSetDeviceTypeOption(deviceTypeList);
		}
	}, [wsData]);

	const onSetDeviceTypeOption = (list) => {
		if(list[0]?.jcommand?.length > 0) {
			setJcommand(list[0].jcommand);
			const commandType = list[0].jcommand.map((data) => {
				return ({
						label: data.name,
						value: data.type
					}
				);
			});
			setTypeOptions(commandType);
			onSetCommandTypeOptions(type)
		}
	};

	const onSearchData = async (id) => {
		await api.getDeviceTypeList({deviceTypeId: id});
	};

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		if(elm) {
			console.log('hello elm=>', elm);
			const {id, deviceId, deviceName, deviceTypeId, typeName, type, commandType, commandValue, isactivated} = elm;
			setId(id);
			setDeviceId(deviceId);
			setDeviceName(deviceName);
			setDeviceTypeId(deviceTypeId);
			setTypeName(typeName);
			setType(type);
			setCommandType(commandType);
			setCommandValue(commandValue);
			setIsactivated(isactivated);
			onSearchData(deviceTypeId).catch();
		}
	};

	const onClose = () => {
		setVisible(false);
		form.resetFields();
		resetFields();
	};

	const onSubmit = (values) => {
		console.log('hello values=>', values);
		if(values.deviceName === undefined && !deviceName) {
			return util.showWarning('디바이스 이름을 선택해주세요.');
		}
		if(values.type === undefined && !type) {
			return util.showWarning('명령항목을 선택해주세요.');
		}
		if(values.commandValue === undefined && !commandValue) {
			return util.showWarning('명령을 선택해주세요.');
		}
		const dataInfo = {...values, id, deviceId, deviceName, deviceTypeId, type, typeName, commandType, commandValue, isactivated};
		setVisible(false);
		getJactionData(dataInfo);
		form.resetFields();
		resetFields();
	};

	const getSelectedDeviceData = (data) => {
		if(data && data[0]) {
			setTypeName(undefined);
			setType(undefined);
			setCommandType(undefined);
			setCommandValue(undefined);
			form.resetFields(['type']);
			form.resetFields(['commandValue']);
			const deviceId = data[0].device_id;
			const deviceName = data[0].device_name;
			const deviceTypeId = data[0].device_type_id;
			setDeviceId(deviceId);
			setDeviceName(deviceName);
			setDeviceTypeId(deviceTypeId);
			form.setFieldsValue({deviceName: deviceName});
			onSearchData(deviceTypeId).catch();
		}
	};

	const onSetCommandTypeOptions = (value) => {
		if(jcommand) {
			const command = jcommand?.filter(x => x.type === value)[0]?.command;
			const option = command?.map((c) => {
				return ({
					label: c.type,
					value: c.value
				});
			});
			setCommandValueOptions(option);
		}
	};

	const onTypeChange = (value, e) => {
		setCommandValueOptions([]);
		onSetCommandTypeOptions(value);
		setTypeName(e.children);
		setType(value);
	};

	const onCommandValueChange = (value, e) => {
		setCommandType(e.children);
		setCommandValue(value);
	};

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
	};

	const resetFields = () => {
		form.resetFields(['deviceName']);
		form.resetFields(['type']);
		form.resetFields(['commandValue']);
		setDeviceId(undefined);
		setDeviceName(undefined);
		setDeviceTypeId(undefined);
		setTypeName(undefined);
		setType(undefined);
		setCommandType(undefined);
		setCommandValue(undefined);
		setJcommand(undefined);
		setIsactivated(false);
		setTypeOptions([]);
		setCommandValueOptions([]);
	};

	const buttonStyle = title === labels.SMART_ROUTINE_JACTION_EDIT ?
		{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'} :
		{display: 'flex', justifyContent: 'flex-end', marginTop: '12px', marginRight: '8px'};

	return (
		<div style={buttonStyle}>
			<Button type="primary"
			        icon={title === labels.SMART_ROUTINE_JACTION_EDIT ? <SettingOutlined/> :
				        <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={'small'}>
				{title === labels.SMART_ROUTINE_JACTION_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={16}>
						<Col span={10}>
							<Form.Item
								name="deviceName"
								label="디바이스 이름"
							>
								<Input disabled={true} defaultValue={deviceName} placeholder='디바이스 이름을 선택하세요'/>
							</Form.Item>
						</Col>
						<Col span={2} style={{marginTop: '29px'}}>
							<DeviceSelectInDrawer title={title} elm={elm} houseId={houseId} getSelectedDeviceData={getSelectedDeviceData}/>
						</Col>
            <Col span={12}>
							<Form.Item
								name="type"
								label="명령항목"
							>
								<Select defaultValue={type} onChange={onTypeChange} placeholder="명령항목을 선택하세요">
									{typeOptions?.map((type) => {
										return (
											<Option value={type.value} name={type.label}>{type.label}</Option>
										)
									})}
								</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
							<Form.Item
								name="commandValue"
								label="명령"
							>
								<Select defaultValue={commandType} onChange={onCommandValueChange} placeholder="명령을 선택하세요">
									{commandValueOptions?.map((command) => {
										return (
											<Option value={command.value} name={command.value}>{command.label}</Option>
										)
									})}
								</Select>
              </Form.Item>
            </Col>
						<Col span={12}>
							<Form.Item
								name="isactivated"
								label="활성화"
								initialValue={isactivated}
								getValueFromEvent={e => handleUserActivativeSwitch(e)}
							>
								<Switch checked={isactivated}/>
							</Form.Item>
						</Col>
          </Row>
          <Form.Item
            style={{
              textAlign: 'center',
              marginTop: '50px'
            }}
          >
            <Button
              onClick={() => onClose(elm)}
              style={{marginRight: 8}}
            >
              취소
            </Button>
            <Button htmlType="submit" type="primary">
              {title === labels.SMART_ROUTINE_JACTION_EDIT ? '수정' : '추가'}
            </Button>
          </Form.Item>
        </Form>
			</Drawer>
		</div>
	);
}

export default SmartRoutineJactionFormInDrawer;
