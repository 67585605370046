import React from 'react'

export const pagination = {
  page: 1,
  rowCount: 10,
  searchWord: '',
  orderBy: '',
  isDesc: true
};

export const userTypeOptions = [
  {label: 'Super admin', value: 0},
  {label: 'Manager', value: 1},
  {label: 'Power User', value: 2},
  {label: 'User', value: 3},
];

export const protocolOption = [
  {label: 'Wi-Fi', value: 'WIFI'},
  {label: 'ZigBee', value: 'ZIGBEE'},
];

export const houseTypeOptions = [
  {label: '아파트', value: 'APT'},
  {label: '단독주택', value: 'HOUSE'},
];

export const scopeOptions = [
  {label: '클때', value: 'over'},
  {label: '같을때', value: 'equal'},
  {label: '작을때', value: 'under'},
];

export const daysOptions = [
  {label: '전체', value: 0},
  {label: '평일', value: 1},
  {label: '주말', value: 2},
];

export const unitOptions = [
  {label: '℃', value: '\u00b0C'},
  {label: '%', value: '%'},
];

export const deviceKindOptions = [
  {label: 'airKnife', value: 'airKnife'},
  // {label: 'airKnifeRear', value: 'airKnifeRear'},
  {label: 'airPurifier', value: 'airPurifier'},
  {label: 'hvac', value: 'hvac'},
  {label: 'sensorDevice', value: 'sensorDevice'},
];

export const typeOfTime = {
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};
