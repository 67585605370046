export const HOUSE_LIST = 'HOUSE_LIST';
export const USER_DATA = 'USER_DATA';
export const USER_LIST = 'USER_LIST';
export const HOUSE_USER_LIST = 'HOUSE_USER_LIST';
export const MANAGER_HOUSE_LIST = 'MANAGER_HOUSE_LIST';
export const DEVICE_DATA_LIST = 'DEVICE_DATA_LIST';
export const DEVICE_TYPE_LIST = 'DEVICE_TYPE_LIST';
export const DEVICE_LIST = 'DEVICE_LIST';
export const LOGIN_LOG_LIST = 'LOGIN_LOG_LIST';
export const ERROR_LOG_LIST = 'ERROR_LOG_LIST';
export const HOUSE_LIST_TOTAL = 'HOUSE_LIST_TOTAL';
export const USER_LIST_TOTAL = 'USER_LIST_TOTAL';
export const HOUSE_USER_LIST_TOTAL = 'HOUSE_USER_LIST_TOTAL';
export const MANAGER_HOUSE_LIST_TOTAL = 'MANAGER_HOUSE_LIST_TOTAL';
export const DEVICE_TYPE_LIST_TOTAL = 'DEVICE_TYPE_LIST_TOTAL';
export const DEVICE_LIST_TOTAL = 'DEVICE_LIST_TOTAL';
export const LOGIN_LOG_LIST_TOTAL = 'LOGIN_LOG_LIST_TOTAL';
export const ERROR_LOG_LIST_TOTAL = 'ERROR_LOG_LIST_TOTAL';
export const DEVICE_DATA_LIST_TOTAL = 'DEVICE_DATA_LIST_TOTAL';
export const SMART_ROUTINE_LIST = 'SMART_ROUTINE_LIST';
export const SMART_ROUTINE_LIST_TOTAL = 'SMART_ROUTINE_LIST_TOTAL';
export const SMART_ALARM_LIST = 'SMART_ALARM_LIST';
export const SMART_ALARM_LIST_TOTAL = 'SMART_ALARM_LIST_TOTAL';
export const ICON_LIST = 'ICON_LIST';
export const ICON_LIST_TOTAL = 'ICON_LIST_TOTAL';
export const BRANCH_LIST = 'BRANCH_LIST';
export const BRANCH_LIST_TOTAL = 'BRANCH_LIST_TOTAL';
export const BRANCH_GROUP_LIST = 'BRANCH_GROUP_LIST';
export const BRANCH_GROUP_LIST_TOTAL = 'BRANCH_GROUP_LIST_TOTAL';
export const BRANCH_TYPE_LIST = 'BRANCH_TYPE_LIST';
export const DEVICE_VIEW_LIST = 'DEVICE_VIEW_LIST';
export const DEVICE_VIEW_LIST_TOTAL = 'DEVICE_VIEW_LIST_TOTAL';
export const DATA_LIST = 'DATA_LIST';
export const DATA_LIST_TOTAL = 'DATA_LIST_TOTAL';
export const DID_INFO_LIST = 'DID_INFO_LIST';
export const DID_INFO_LIST_TOTAL = 'DID_INFO_LIST_TOTAL';
