import React from 'react'
import {useSelector} from "react-redux";
import {api} from "../../services/service";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import util from "../../utils";

export const Data = ({match}) => {
	const [dataList, setDataList] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [searchData, setSearchData] = React.useState();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [isRefresh, setIsRefresh] = React.useState(false);
	const wsData = useSelector(state => state.ws);
	const rowCount = 12;
	const fDate = util.toStartDateTS(util.getCurrentDate());
	const tDate = util.toEndDateTS(util.getCurrentDate());

	const onSearch = async (searchInfo) => {
		setSearchData(searchInfo);
		const tableInfo = {...pagination, page: currentPage, ...searchInfo, rowCount};
		await api.getDeviceDataList(tableInfo);
	};

	React.useEffect(() => {
		if(match.params.ts) {
			setIsRefresh(true);
		}
		const fromDate = searchData?.fromDate ? searchData.fromDate : fDate;
		const toDate = searchData?.toDate ? searchData.toDate : tDate;
		onSearch({page: 1, searchWord: searchData?.searchWord, fromDate, toDate}).catch();
	}, [match.params.ts]);

	React.useEffect(() => {
		pagination["searchWord"] = '';
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		setDataList(wsData.deviceDataList);
		setTotalCount(wsData.deviceDataListTotal);
	}, [wsData]);

	const setRefresh = (data) => {
		const {isRefresh, page} = data
		setSearchData(data);
		setIsRefresh(isRefresh);
		setCurrentPage(page);
	};

	const getCurrentPage = (page) => {
		setCurrentPage(page);
	};

	const tableColumns = [
		{
			title: '날짜',
			dataIndex: 'created',
			width: 200,
			render: (_, record) => (
				<div className="d-flex">
					{util.formatDTS(record['created'])}
				</div>
			),
			sorter: true,
		},
		{
			title: '브랜치 이름',
			dataIndex: 'branch_name',
			width: 200,
			sorter: true,
		},
		{
			title: '정류장 이름',
			dataIndex: 'house_name',
			width: 250,
			sorter: true,
		},
		{
			title: '운행방향',
			dataIndex: 'bus_direction',
			width: 230,
			sorter: true,
		},
		{
			title: '시리얼',
			dataIndex: 'serial',
			width: 100,
			sorter: true,
		},
		{
			title: '디바이스 이름',
			dataIndex: 'device_name',
			width: 200,
			sorter: true,
		},
		{
			title: 'PM2.5',
			dataIndex: 'pm25',
			width: 50,
		},
		{
			title: 'PM10',
			dataIndex: 'pm10',
			width: 50,
		},
		{
			title: 'CO2',
			dataIndex: 'co2',
			width: 50,
		},
		{
			title: 'Temperature',
			dataIndex: 'temperature',
			width: 50,
		},
		{
			title: 'Humidity',
			dataIndex: 'humidity',
		}
	];
	return (
		<div>
			<DataTableView
				columns={tableColumns}
				dataSource={dataList}
				rowKey='id'
				pageSize={rowCount}
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				showSearchDate={true}
				isRefresh={isRefresh}
				setRefresh={setRefresh}
			/>
		</div>
	);
};

export default Data;
