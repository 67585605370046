import React from "react";
import {Drawer, Button, Table, Card, Pagination} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import DataTableView from "./DataTableView";
import {useSelector} from "react-redux";
import util from "../../utils";

const DeviceSelectInDrawer = ({elm, houseId, getSelectedDeviceData}) => {
	const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [deviceList, setDeviceList] = React.useState([]);
	const [deviceListTotal, setDeviceListTotal] = React.useState(0);
	const [current, setCurrent] = React.useState(1);
	const wsData = useSelector(state => state.ws);
	const rowCount = 10;

	const onSearch = async (tableInfo) => {
		await api.getDeviceList({...tableInfo, rowCount, houseId}).catch();
	}

	React.useEffect(() => {
		setDeviceList(wsData.deviceList)
		setDeviceListTotal(wsData.deviceListTotal);
	}, [wsData])


	const showDrawer = (elm) => {
		if(houseId) {
			onSearch().catch();
			setVisible(true);
		} else {
			return util.showWarning('정류장을 선택해주세요.');
		}
	};

	const onClose = (elm) => {
		setVisible(false);
	};

	const onSubmit = () => {
		setVisible(false);
		getSelectedDeviceData(selectedRows);
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		type: 'radio',
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
		],
	};

	const onChange = (value) => {
		setCurrent(value);
		onSearch({page: value, rowCount}).catch();
	};

	const columns = [
		{
			title: '디바이스 이름',
			dataIndex: 'device_name',
			width: '23%',
			key: 'device_name',
		},
		{
			title: '디바이스 타입',
			dataIndex: 'device_type_name',
			key: 'device_type',
		},
	];

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="ghost"
			        icon={<SearchOutlined/>}
			        onClick={() => showDrawer(elm)} size={'small'} />
			<Drawer
				title={labels.DEVICE_SELECT}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				{/*<DataTableView rowSelection={rowSelection} columns={columns} dataSource={deviceList} rowKey='device_id' totalCount={deviceListTotal} pageSize={rowCount} onSearch={onSearch} />*/}
				<Card bodyStyle={{'padding': '0px'}}>
					<Table rowKey='device_id' rowSelection={rowSelection} columns={columns} dataSource={deviceList} pagination={false} />
				</Card>
				<div style={{paddingTop: 20, textAlign: 'right'}}>
					<Pagination current={current} pageSize={rowCount} total={deviceListTotal} onChange={onChange} showSizeChanger={false}/>
				</div>
				<div
						style={{
							textAlign: 'center',
							marginTop: '50px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} type="primary">
							선택
						</Button>
					</div>
			</Drawer>
		</div>
	);

}

export default DeviceSelectInDrawer;
