import React from "react";
import {Card, Drawer, Button, Input, Table, Space, Pagination} from 'antd';
import Highlighter from 'react-highlight-words';
import {PlusOutlined, SearchOutlined, SettingOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import {useSelector} from "react-redux";
import DataTableView from "./DataTableView";
import {pagination} from "../../utils/helper";

const HouseSelectInDrawer = ({title, elm, getSelectedHouseData}) => {
	const [searchText, setSearchText] = React.useState();
	const [searchedColumn, setSearchedColumn] = React.useState();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [houseList, setHouseList] = React.useState([]);
	const [managerHouseList, setManagerHouseList] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [current, setCurrent] = React.useState(1);
	const wsData = useSelector(state => state.ws);
	const pTitle = (title === labels.USER_ADD || title === labels.DEVICE_ADD) ? labels.HOUSE_SELECT_ADD : labels.HOUSE_SELECT_EDIT;
	let loopList = [];
	const rowCount = 10;

	const onSearch = async (tableInfo) => {
		// await api.getHouseList(tableInfo).catch();
		await api.getHouseArray(tableInfo).catch();
	};

	React.useEffect(() => {
		setHouseList(wsData.houseList)
		setTotalCount(wsData.houseListTotal);
		if (title === labels.USER_EDIT) {
			setManagerHouseList(wsData.manegerhouselist)
		}
	}, [wsData]);

	React.useEffect(() => {
		// 각 하우스리스트에 key를 부여하기 위한 flow
		loopList = houseList??[];
		for (let key of loopList) {
			if (typeof key["house_id"] === 'number') {
				key["key"] = key["house_id"]
			}
		}
		setTableData(loopList)
	}, [houseList]);

	const showDrawer = (elm) => {
		onSearch({rowCount}).catch();
		setVisible(true);
		let selectedHouse = []
		for (let key of managerHouseList) {
			selectedHouse.push(key["house_id"])
		}
		setSelectedRowKeys(selectedHouse)
		if(title === labels.DEVICE_EDIT || title === labels.SMART_ROUTINE_EDIT){
			setSelectedRowKeys([elm["house_id"]])
		}
	};

	const onClose = (elm) => {
		setVisible(false);
	};

	const onSubmit = () => {
		setVisible(false);
		getSelectedHouseData(selectedRows);
	}

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Input
					placeholder={dataIndex === 'house_name' ? `하우스 이름 검색하기` : `브랜치 검색하기`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{marginBottom: 8, display: 'block'}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined/>}
						size="small"
						style={{width: 90}}
					>
						검색
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
						리셋
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({closeDropdown: false});
							setSearchText(selectedKeys[0])
							setSearchedColumn(dataIndex)
						}}
					>
						필터
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
			}
		},
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0])
		setSearchedColumn(dataIndex)
	};

	const handleReset = clearFilters => {
		clearFilters();
		setSearchText(undefined);
	};

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		type: (title === labels.USER_ADD || title === labels.USER_EDIT) ? 'checkbox' : 'radio',
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
		],
	};

	const onChange = (value) => {
		setCurrent(value);
		onSearch({page: value, rowCount}).catch();
	};

	const columns = [
		{
			title: '브랜치 이름',
			dataIndex: 'branch_name',
			width: 150,
			key: 'branch_name',
			...getColumnSearchProps('branch_name'),
		},
		{
			title: '정류장 이름',
			dataIndex: 'house_name',
			width: 200,
			key: 'house_name',
			...getColumnSearchProps('house_name'),
		},
		{
			title: '운행방향',
			dataIndex: 'bus_direction',
			width: 200,
			key: 'bus_direction',
		},
	];

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			{title === labels.USER_ADD || title === labels.USER_EDIT ?
			<Button style={{marginTop: '-40px'}} type="primary"
			        icon={title === labels.USER_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={'small'}>
				{title === labels.USER_EDIT ? '하우스 수정' : '하우스 추가'}
			</Button> :
			<Button type="ghost"
			        icon={<SearchOutlined/>}
			        onClick={() => showDrawer(elm)} size={'small'} />}
			<Drawer
				title={pTitle}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				{/*<DataTableView rowSelection={rowSelection} columns={columns} dataSource={tableData} totalCount={totalCount} pageSize={rowCount} onSearch={onSearch} />*/}
				<Card bodyStyle={{'padding': '0px'}}>
					<Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
				</Card>
				<div style={{paddingTop: 20, textAlign: 'right'}}>
					<Pagination current={current} pageSize={rowCount} total={totalCount} onChange={onChange} showSizeChanger={false}/>
				</div>
				<div
					style={{
						textAlign: 'center',
						marginTop: '50px'
					}}
				>
					<Button
						onClick={() => onClose(elm)}
						style={{marginRight: 8}}
					>
						취소
					</Button>
					<Button onClick={onSubmit} type="primary">
						선택
					</Button>
				</div>
			</Drawer>
		</div>
	);
}

export default HouseSelectInDrawer;
