import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Select, Switch} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import {deviceKindOptions} from "../../utils/helper";
import HouseSelectInDrawer from "./HouseSelectInDrawer";
import {useSelector} from "react-redux";
import util from "../../utils";
import DeviceSelectInDrawer from "./DeviceSelectInDrawer";

const {Option} = Select;

const DIDFormInDrawer = ({title, elm, onSearch, page}) => {
	const [deviceTypeOptions, setDeviceTypeOptions] = React.useState([]);
	const [selectDeviceHouse, setSelectDeviceHouse] = React.useState(); // 디바이스에서 하우스 선택할 때 사용
	const [isactivated, setIsactivated] = React.useState(false);
	const [houseName, setHouseName] = React.useState();
	const [busDirection, setBusDirection] = React.useState();
	const [deviceTypeName, setDeviceTypeName] = React.useState();
	const [deviceTypeId, setDeviceTypeId] = React.useState();
	const [serial, setSerial] = React.useState();
	const [deviceId, setDeviceId] = React.useState();
	const [deviceName, setDeviceName] = React.useState();
	const [deviceKind, setDeviceKind] = React.useState();
	const [houseId, setHouseId] = React.useState();
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const [houseList, setHouseList] = React.useState([]);
	const [houseListTotal, setHouseListTotal] = React.useState(0);
	const [itemTypes, setItemTypes] = React.useState();
	const [apiKey, setApiKey] = React.useState();
	const wsData = useSelector(state => state.ws);
	const [buttonSize, setButtonSize] = React.useState(title === labels.DID_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	const onSearchDeviceType = async (tableInfo) => {
		await api.getDeviceTypeArray(tableInfo);
	};

	React.useEffect(() => {
		setHouseList(wsData.houseList)
		setHouseListTotal(wsData.houseListTotal);
		const deviceTypeList = wsData.deviceTypeList;
		let typeList = [];
		if(deviceTypeList) {
			for (let key of deviceTypeList) {
				typeList.push({label: key[1], value: key[0]});
			}
		}
		setDeviceTypeOptions(typeList);
	}, [wsData])

	const showDrawer = (elm) => {
		onSearchDeviceType().catch();
		resetFields();
		setVisible(true);
		if (elm) {
			const {
				device_id: deviceId,
				house_id: houseId,
				house_name: houseName,
				bus_direction: busDirection,
				device_type_id: deviceTypeId,
				device_type_name: deviceTypeName,
				device_name: deviceName,
				device_kind: deviceKind,
				serial,
				isactivated
			} = elm;
			setHouseId(houseId);
			setHouseName(houseName);
			setBusDirection(busDirection);
			setDeviceTypeId(deviceTypeId);
			setDeviceTypeName(deviceTypeName);
			setDeviceName(deviceName);
			setDeviceKind(deviceKind);
			setSerial(serial);
			setIsactivated(isactivated);
			setInputData({...inputData, deviceId, houseId, busDirection, deviceTypeId, deviceName, serial, isactivated, deviceKind});
		}
	};

	const onClose = () => {
		setVisible(false);
		resetFields();
	};

	const onSubmit = async () => {
		if(houseId === undefined) {
			return util.showWarning('정류장 이름을 선택해주세요.');
		}
		if(deviceId === undefined) {
			return util.showWarning('디바이스 이름을 선택해주세요.');
		}
		if(inputData['apiKey'] === undefined) {
			return util.showWarning('API Key를 작성해주세요.');
		}
		if(inputData['itemTypes'] === undefined) {
			return util.showWarning('Item Types을 작성해주세요.');
		}
		if(inputData) {
			setVisible(false);
			await api.setDidInfo(inputData).then(res => {
				if(res) {
					onSearch({page});
					resetFields();
					util.showSuccess(labels.SUCCESS_MESSAGE);
				}
			});
		}
	}

	const resetFields = () => {
		form.resetFields();
		setHouseId(undefined);
		setHouseName(undefined);
		setBusDirection(undefined);
		setDeviceTypeId(undefined);
		setDeviceTypeName(undefined);
		setDeviceId(undefined);
		setDeviceName(undefined);
		setDeviceKind(undefined);
		setSerial(undefined);
		setIsactivated(false)
		setInputData(undefined);
	};

	const getSelectedDeviceData = (data) => {
		if(data && data[0]) {
			setDeviceId(data[0].device_id);
			setDeviceName(data[0].device_name);
			setInputData({...inputData, deviceId: data[0].device_id})
		}
		const deviceId = data[0].device_id;
		const deviceName = data[0].device_name;
		setDeviceId(deviceId);
		setDeviceName(deviceName);
		setInputData({...inputData, deviceId});
		form.setFieldsValue({deviceName: deviceName});
	};

	const getSelectedHouseData = (data) => {
		const houseId = data[0].house_id;
		const houseName = data[0].house_name;
		const busDirection = data[0].bus_direction;
		setHouseId(houseId);
		setHouseName(houseName);
		setBusDirection(busDirection);
		setSelectDeviceHouse(data[0]);
		setInputData({...inputData, houseId, busDirection});
		form.setFieldsValue({houseName: houseName});
		form.setFieldsValue({busDirection: busDirection});
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;

		setInputData({...inputData, [name]: value});
	}

	const handleSelectData = (value, name) => {
		setInputData({...inputData, [name]: value});
	}

	const handleUserActivativeSwitch = value => {
		setIsactivated(value);
		setInputData({...inputData, isactivated: value});
	}

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
			        icon={title === labels.DID_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.DID_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={10}>
							<Form.Item
								name="houseName"
								label="정류장 이름"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} disabled={true} defaultValue={houseName} placeholder='정류장 이름을 선택하세요'/>
							</Form.Item>
						</Col>
						<Col span={2} style={{marginTop: '28px'}}>
							<HouseSelectInDrawer title={title} elm={elm} getSelectedHouseData={getSelectedHouseData}/>
						</Col>
						<Col span={12}>
							<Form.Item
								name="busDirection"
								label="운행방향"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input required={true} disabled={true} defaultValue={busDirection} placeholder="운행방향을 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={10}>
							<Form.Item
								name="deviceName"
								label="디바이스 이름"
							>
								<Input disabled={true} defaultValue={deviceName} placeholder='디바이스 이름을 선택하세요'/>
							</Form.Item>
						</Col>
						<Col span={2} style={{marginTop: '29px'}}>
							<DeviceSelectInDrawer title={title} elm={elm} houseId={houseId} getSelectedDeviceData={getSelectedDeviceData} />
						</Col>
						<Col span={12}>
							<Form.Item
								name="apiKey"
								label="API Key"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={apiKey} placeholder="API Key를 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="itemTypes"
								label="Item Types"
								getValueFromEvent={e => handleInputData(e)}
							>
								<Input defaultValue={itemTypes} placeholder="Item Types을 작성하세요"/>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '50px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							취소
						</Button>
						<Button onClick={onSubmit} htmlType={"submit"} type="primary">
							{title === labels.DEVICE_EDIT ? '수정' : '추가'}
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default DIDFormInDrawer;
