import React from "react";
import {Drawer, Form, Button, Col, Row, Select, Table} from 'antd';
import {PlusOutlined, WifiOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../../views/Labels';
import {useSelector} from "react-redux";
import util from "../../utils";
import {typeOfTime} from "../../utils/helper";

const {Option} = Select;

const DeviceViewFormInDrawer = ({title, elm}) => {
	const [isactivated, setIsactivated] = React.useState(false);
	const [houseName, setHouseName] = React.useState();
	const [deviceTypeName, setDeviceTypeName] = React.useState();
	const [deviceTypeId, setDeviceTypeId] = React.useState();
	const [serial, setSerial] = React.useState();
	const [deviceId, setDeviceId] = React.useState();
	const [deviceName, setDeviceName] = React.useState();
	const [deviceKind, setDeviceKind] = React.useState();
	const [houseId, setHouseId] = React.useState();
	const [protocol, setProtocol] = React.useState();
	const [cmdTypeOptions, setCmdTypeOptions] = React.useState([]);
	const [cmdValueOptions, setCmdValueOptions] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({isDel: false});
	const [deviceViewList, setDeviceViewList] = React.useState([]);
	const [tableData, setTableData] = React.useState([]);
	const [received, setReceived] = React.useState();
	const [deviceViewListTotal, setDeviceViewListTotal] = React.useState(0);
	const wsData = useSelector(state => state.ws);
	const [buttonSize, setButtonSize] = React.useState(title === labels.DEVICE_VIEW ? 'small' : null);
	const [sendCmdData, setSendCmdData] = React.useState({});
	const [form] = Form.useForm();

	React.useEffect(() => {
		const deviceView = wsData.deviceViewList;
		setDeviceViewList(deviceView);
		setDeviceViewListTotal(wsData.deviceViewListTotal);
		onSetJvalueTableData(deviceView);
	}, [wsData]);

	React.useEffect(() => {
		if (deviceViewList?.length > 0) {
			if (deviceViewList[0]) {
				setCmdTypeOptions(deviceViewList[0]['jcommand'])
			}

			if (deviceViewList[0]['received'] > 0) {
				setReceived(util.formatDTS(deviceViewList[0]['received']));
			} else {
				setReceived(undefined);
			}
		}
	}, [deviceViewList]);

	const onSetJvalueTableData = (deviceView) => {
		let jvalueTableData = [];
		if(deviceView?.length > 0) {
			const jvalue = deviceView[0].jvalue;
			if (jvalue) {
				for(let key in jvalue) {
					if (typeof jvalue[key] === 'object') {
						jvalueTableData.push({name: key, data: JSON.stringify(jvalue[key])});
					} else if (typeof jvalue[key] === 'boolean') {
						jvalueTableData.push({name: key, data: jvalue[key] ? 'Activate' : 'Deactivate'});
					} else {
						jvalueTableData.push({name: key, data: jvalue[key]})
					}
				}
			}
		}
		setTableData(jvalueTableData);
	};

	const onSearchDeviceView = async (deviceId) => {
		await api.getDeviceViewList({deviceId});
	};

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		if (elm) {
			const {
				device_id: deviceId,
				device_kind: deviceKind,
				device_name: deviceName,
				device_type_id: deviceTypeId,
				device_type_name: deviceTypeName,
				house_id: houseId,
				house_name: houseName,
				isactivated,
				protocol,
				serial
			} = elm;
			setSendCmdData({...sendCmdData, deviceId: deviceId})
			onSearchDeviceView(deviceId).catch();
			setDeviceId(deviceId);
			setDeviceKind(deviceKind);
			setDeviceName(deviceName);
			setDeviceTypeId(deviceTypeId);
			setDeviceTypeName(deviceTypeName);
			setHouseId(houseId);
			setHouseName(houseName);
			setIsactivated(isactivated);
			setProtocol(protocol);
			setSerial(serial);
			setInputData({
				...inputData,
				deviceId,
				deviceKind,
				deviceName,
				deviceTypeId,
				deviceTypeName,
				houseId,
				houseName,
				isactivated,
				protocol,
				serial
			});
		}
	};

	const onClose = () => {
		setVisible(false);
		resetFields();
	};

	const onSubmit = async () => {
		if(sendCmdData){
			await api.setDeviceSendCmd(sendCmdData).catch()
			onClose();
		}
	}

	const resetFields = () => {
		form.resetFields();
		setHouseId(undefined);
		setHouseName(undefined);
		setDeviceTypeId(undefined);
		setDeviceTypeName(undefined);
		setDeviceName(undefined);
		setDeviceKind(undefined);
		setSerial(undefined);
		setIsactivated([])
		setInputData(undefined);
		setSendCmdData({})
	};

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;

		setInputData({...inputData, [name]: value});
	}

	const selectCmdType = (e) => {
		console.log('selectCmdType', e)
		let cmd = cmdTypeOptions;

		for (let key of cmd) {
			if (key['type'] === e) {
				setCmdValueOptions(key['command'])
			}
		}

		setSendCmdData({...sendCmdData, cmdType: e})
	}

	const selectCmdValue = (e) => {
		console.log('selectCmdValue', e)
		console.log('cmdValueOptions', cmdValueOptions)
		let valuesName = '';

		for(let key of cmdValueOptions){
			if(key['value'] === e){
				valuesName = key['type']
			}
		}

		setSendCmdData({...sendCmdData, cmdValue: Number(e), cmdValueName: valuesName})
	}

	const tableColumns = [
		{
			title: '이름',
			dataIndex: 'name',
		},
		{
			title: '값',
			dataIndex: 'data',
		}
	];

	const viewStyle = elm.received > util.toTS(util.getCurrentTimeAgo(10, typeOfTime.MINUTES)) ? {} : {backgroundColor: 'red', borderColor: 'red'};

	return (
		<div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
			<Button type="primary"
							style={viewStyle}
			        icon={title === labels.DEVICE_VIEW ? <WifiOutlined /> : <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.DEVICE_VIEW ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				<Form layout="vertical" hideRequiredMark form={form}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name="jvalue"
								label={`${houseName} (${deviceName})`}
								getValueFromEvent={e => handleInputData(e)}
							>
								<div style={{marginBottom: 10}}>
									<text style={{marginRight: 10, fontWeight: 'bold'}}>수신:</text>
									<text>{received}</text>
								</div>
								<Table
									columns={tableColumns}
									dataSource={tableData}
									rowKey='id'
									bordered
									pagination={false}
									scroll={{y: 430}}
								/>
							</Form.Item>
						</Col>
					</Row>
					{cmdTypeOptions.length > 0 ?
						<Row gutter={16}>
							<Col span={10}>
								<Form.Item
									name="jvalue"
									label=""
									getValueFromEvent={e => selectCmdType(e)}
								>
									<Select required={true} placeholder="명령어 항목을 선택하세요">
										{cmdTypeOptions?.map((type) => {
											return (
												<Option value={type.type} name={type.name}>{type.type}</Option>
											)
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={10}>
								<Form.Item
									name="jvalue"
									label=""
									getValueFromEvent={e => selectCmdValue(e)}
								>
									<Select required={true} placeholder="명령어 값을 선택하세요">
										{cmdValueOptions?.map((type) => {
											return (
												<Option value={type.value} name={type.type}>{type.type}</Option>
											)
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Button onClick={onSubmit} htmlType={"submit"} type="primary">
									{title === labels.DEVICE_VIEW ? '보내기' : '추가'}
								</Button>
							</Col>
						</Row> :
						undefined
					}
					<Form.Item
						style={{
							textAlign: 'center',
							marginTop: '50px'
						}}
					>
						<Button
							onClick={() => onClose(elm)}
							style={{marginRight: 8}}
						>
							닫기
						</Button>
					</Form.Item>
				</Form>
			</Drawer>
		</div>
	);

}

export default DeviceViewFormInDrawer;
