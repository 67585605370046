import React from 'react'
import {Card, Table, Pagination, DatePicker, Input, Button} from 'antd';
import {pagination} from "../../utils/helper";
import util from "../../utils";
import {SearchOutlined} from "@ant-design/icons";
import { Resizable } from "react-resizable";
import DeviceFormInDrawer from "./DeviceFormInDrawer";
import {labels} from "../../views/Labels";
import SmartRoutineFormInDrawer from "./SmartRoutineFormInDrawer";
import UserFormInDrawer from "./UserFormInDrawer";
import BranchFormInDrawer from "./BranchFormInDrawer";
import HouseFormInDrawer from "./HouseFormInDrawer";
import DeviceTypeFormInDrawer from "./DeviceTypeFormInDrawer";
import DIDFormInDrawer from "./DIDFormInDrawer";

// export interface DataTableProps {
// 	columns?: Object;
// 	dataSource?: Object;
// 	rowKey?: string;
// 	totalCount?: number;
// 	onSearch?: () => void;
// 	getCurrentPage?: () => void;
// 	showSearchWord?: boolean;
// 	isRefresh?: boolean;
// 	setRefresh?: () => void;
// 	title?: string;
// };

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  const style = {
		position: 'absolute',
		right: '-5px',
		bottom: 0,
		zIndex: 1,
		width: '10px',
		height: '100%',
		cursor: 'col-resize',
	};
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          style={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const DataTableView = (props) => {
	const {RangePicker} = DatePicker;
	const initialDateRef = React.useRef(util.getCurrentDate());
	const [current, setCurrent] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [searchWord, setSearchWord] = React.useState();
	const [fromDate, setFromDate] = React.useState(util.toStartDateTS(util.getCurrentDate()));
	const [toDate, setToDate] = React.useState(util.toEndDateTS(util.getCurrentDate()));
	const [isDesc, setIsDesc] = React.useState(!pagination.isDesc);
	const [columns, setColumns] = React.useState(props.columns);

	React.useEffect(() => {
		if (props.rowKey !== 'houseSelect') {
			if (props.dataSource?.length > 0) {
				setLoading(false);
			} else {
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			}
		}
		if(props.isRefresh) {
			setCurrent(1);
			props.setRefresh({isRefresh: false, page: 1, searchWord, fromDate, toDate});
		}
	}, [props.dataSource]);

	const onChange = (value) => {
		if(props.getCurrentPage) {
			props.getCurrentPage(value);
		}
		setCurrent(value);
		if(props.showSearchDate) {
			props.onSearch({page: value, rowCount: props.pageSize ?? pagination.rowCount, searchWord, fromDate, toDate});
		} else {
			props.onSearch({page: value, rowCount: props.pageSize ?? pagination.rowCount, searchWord});
		}
	};

	const onChangeWord = (e) => {
		pagination["searchWord"] = e.target.value;
		setSearchWord(e.target.value);
	};

	const onSearchWord = () => {
		setCurrent(1);
		if(props.showSearchDate) {
			props.onSearch({page: 1, searchWord, fromDate, toDate});
		} else {
			props.onSearch({page: 1, searchWord});
		}
	};

	const handleDatePicker = (dates) => {
		if(dates && dates.length > 1){
			setFromDate(util.toStartDateTS(dates[0]));
			setToDate(util.toEndDateTS(dates[1]));
			setCurrent(1);
			props.onSearch({page: 1, searchWord, fromDate: util.toStartDateTS(dates[0]), toDate: util.toEndDateTS(dates[1])}).catch();
		}
	};

  const components = {
    header: {
      cell: ResizableTitle
    }
  };

	const tableColumns = columns.map((col, index) => ({
		...col,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'],
		onHeaderCell: (column) => ({
			onClick: () => {
				if(col.sorter) {
					const sort = !isDesc;
					setIsDesc(sort);
					setCurrent(1);
					if(col.showSearchDate) {
						props.onSearch({orderBy: column.dataIndex, isDesc: sort, page: 1, searchWord, fromDate, toDate}).catch();
					} else {
						props.onSearch({orderBy: column.dataIndex, isDesc: sort, page: 1, searchWord}).catch();
					}
				}
			},
			width: column.width,
			onResize: (e, {size}) => {
				const nextColumns = [...columns];
				nextColumns[index] = {
					...nextColumns[index],
					width: size.width
				};
				setColumns(nextColumns);
			}
		})
	}));

	let drawer;
	switch (props.title) {
		case labels.DEVICE_ADD:
			drawer = <DeviceFormInDrawer title={labels.DEVICE_ADD} onSearch={props.onSearch}/>;
			break;
		case labels.SMART_ROUTINE_ADD:
			drawer = <SmartRoutineFormInDrawer title={labels.SMART_ROUTINE_ADD} onSearch={props.onSearch}/>;
			break;
		case labels.USER_ADD:
			drawer = <UserFormInDrawer title={labels.USER_ADD} onSearchUser={props.onSearch}/>;
			break;
		case labels.BRANCH_ADD:
			drawer = <BranchFormInDrawer title={labels.BRANCH_ADD} onSearch={props.onSearch}/>;
			break;
		case labels.HOUSE_ADD:
			drawer = <HouseFormInDrawer title={labels.HOUSE_ADD} onSearch={props.onSearch}/>;
			break;
		case labels.DEVICE_TYPE_ADD:
			drawer = <DeviceTypeFormInDrawer title={labels.DEVICE_TYPE_ADD} onSearch={props.onSearch}/>;
			break;
		case labels.DID_ADD:
			drawer = <DIDFormInDrawer title={labels.DID_ADD} onSearch={props.onSearch}/>;
			break;
		default:
			drawer = undefined;
			break;
	}

	return (
		<>
			<div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 11}}>
				<div style={{display: 'flex'}}>
				{props.showSearchWord && (
					<div style={{display: 'flex', marginRight: 10}}>
						<Input style={{marginRight: '3px'}}
									 placeholder="검색하기"
									 onChange={e => onChangeWord(e)}
									 onKeyPress={e => e.key === 'Enter' && onSearchWord()}/>
						<Button onClick={onSearchWord} icon={<SearchOutlined className="mr-0"/>}/>
					</div>
				)}
				{props.showSearchDate && (
					<div style={{marginRight: 10}}>
						<RangePicker defaultValue={[initialDateRef.current, initialDateRef.current]}
												 onChange={(dateStrings) => handleDatePicker(dateStrings)}/>
					</div>
				)}
				</div>
				<div style={{display: 'flex', marginRight: 10}}>
					{drawer}
				</div>
			</div>
			<div style={{width: '100%', height: props.height ?? '100%'}}>
				<Card bodyStyle={{'padding': '0px'}}>
					<div className={`table-responsive`}>
						<Table rowSelection={props.rowSelection}
									 columns={tableColumns}
									 dataSource={props.dataSource}
						       rowKey={props.rowKey}
									 pagination={false}
									 loading={loading}
									 components={components}
									 scroll={{x: 'max-content'}}
						/>
					</div>
				</Card>
			</div>
			{props.dataSource?.length > 0 && !props.pagination ?
				(<div style={{position: 'absolute', bottom: 5, right: 50}}>
					<Pagination current={current}
											pageSize={props.pageSize ?? pagination.rowCount}
											total={props.totalCount}
											onChange={onChange}
											showSizeChanger={false}/>
				</div>) : undefined}
		</>
	)
};

export default DataTableView;
