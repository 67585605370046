import {
	DatabaseOutlined,
	PlusCircleOutlined,
	TeamOutlined,
	MobileOutlined,
	HomeOutlined,
	ToolOutlined,
	BranchesOutlined,
	ScheduleOutlined,
	DashboardOutlined,
	DesktopOutlined,
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {api} from "../services/service";

const owner = api.getUserInfo();

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
	]
}];

const listNavTree = [{
	key: 'list',
	path: `${APP_PREFIX_PATH}/list`,
	title: 'sidenav.list',
	icon: PlusCircleOutlined,
	breadcrumb: false,
	submenu: [
		{
			key: 'list-data',
			path: `${APP_PREFIX_PATH}/list/data`,
			title: 'sidenav.list.data',
			icon: DatabaseOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'list-device',
			path: `${APP_PREFIX_PATH}/list/device`,
			title: 'sidenav.list.device',
			icon: MobileOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'list-smartroutine',
			path: `${APP_PREFIX_PATH}/list/smartroutine`,
			title: 'sidenav.list.smartroutine',
			icon: ScheduleOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'list-user',
			path: `${APP_PREFIX_PATH}/list/user`,
			title: 'sidenav.list.user',
			icon: TeamOutlined,
			breadcrumb: false,
			submenu: []
		},
	]
}];

const settingNavTree = [{
	key: 'setting',
	path: `${APP_PREFIX_PATH}/setting`,
	title: 'sidenav.setting',
	icon: PlusCircleOutlined,
	breadcrumb: false,
	submenu: [
		{
			key: 'setting-branch',
			path: `${APP_PREFIX_PATH}/setting/branch`,
			title: 'sidenav.setting.branch',
			icon: BranchesOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'setting-house',
			path: `${APP_PREFIX_PATH}/setting/house`,
			title: 'sidenav.setting.house',
			icon: HomeOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'setting-deviceType',
			path: `${APP_PREFIX_PATH}/setting/devicetype`,
			title: 'sidenav.setting.deviceType',
			icon: ToolOutlined,
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'setting-did',
			path: `${APP_PREFIX_PATH}/setting/did`,
			title: 'sidenav.setting.did',
			icon: DesktopOutlined,
			breadcrumb: false,
			submenu: []
		},
	]
}];

let navigationConfig;
if (owner) {
	switch (owner["auth"]) {
		case 0:
			navigationConfig = [
				// ...dashBoardNavTree,
				...listNavTree,
				...settingNavTree,
			]
			break;
		case 1:
			navigationConfig = [
				// ...dashBoardNavTree,
				...listNavTree,
			];
			break;
		default:
			navigationConfig = [
				// ...dashBoardNavTree,
				...listNavTree,
			]
	}
} else {
	navigationConfig = [
		// ...dashBoardNavTree,
		...listNavTree,
	]
}


export default navigationConfig;
