import React, {Suspense} from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Login from './login/login';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import Dashboard from './dashboard/Dashboard';
import Data from './list/Data';
import Device from './list/Device';
import UsersList from './list/UserList';
import {SmartRoutine} from "./list/SmartRoutine";
import {Branch} from "./setting/Branch";
import {House} from "./setting/House";
import DeviceType from './setting/DeviceType';
import DID from "./setting/DID";

const Router = () => {
  return(
  <Suspense fallback={<Loading cover="content"/>}>
    <Switch>
      <Route exact path={APP_PREFIX_PATH + '/login'} component={Login} />
      {/*<Route exact path={APP_PREFIX_PATH + '/dashboard/default'} component={Dashboard} />*/}
      <Route exact path={APP_PREFIX_PATH + '/list/data'} component={Data} />
      <Route exact path={APP_PREFIX_PATH + '/list/data/:ts'} component={Data} />
      <Route exact path={APP_PREFIX_PATH + '/list/device'} component={Device} />
      <Route exact path={APP_PREFIX_PATH + '/list/device/:ts'} component={Device} />
      <Route exact path={APP_PREFIX_PATH + '/list/user'} component={UsersList} />
      <Route exact path={APP_PREFIX_PATH + '/list/smartroutine'} component={SmartRoutine} />
      <Route exact path={APP_PREFIX_PATH + '/list/smartroutine/:ts'} component={SmartRoutine} />
      <Route exact path={APP_PREFIX_PATH + '/setting/branch'} component={Branch} />
      <Route exact path={APP_PREFIX_PATH + '/setting/house'} component={House} />
      <Route exact path={APP_PREFIX_PATH + '/setting/devicetype'} component={DeviceType} />
      <Route exact path={APP_PREFIX_PATH + '/setting/did'} component={DID} />
      <Redirect from={APP_PREFIX_PATH} to={APP_PREFIX_PATH + '/list/data'} />
      {/*<Redirect from={APP_PREFIX_PATH} to={APP_PREFIX_PATH + '/dashboard/default'} />*/}
    </Switch>
  </Suspense>
)};

export default Router;
