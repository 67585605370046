import React from 'react'
import {useSelector} from 'react-redux'
import {Tooltip, Button, Popconfirm, Tag} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {api} from '../../services/service'
import {labels} from '../Labels';
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import SmartRoutineFormInDrawer from "../../components/uplink-components/SmartRoutineFormInDrawer";
import util from "../../utils";

export const SmartRoutine = ({match}) => {
	const [smartRoutine, setSmartRoutine] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [searchData, setSearchData] = React.useState();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [isRefresh, setIsRefresh] = React.useState(false);
	const wsData = useSelector(state => state.ws);

	const onSearch = async (searchInfo) => {
		setSearchData(searchInfo);
		const tableInfo = {...pagination, page: currentPage, ...searchInfo};
		setTimeout(() => {
			api.getSmartRoutineList(tableInfo);
		}, 0);
	};

	React.useEffect(() => {
		if(match.params.ts) {
			setIsRefresh(true);
		}
		onSearch({page: 1, searchWord: searchData?.searchWord}).catch();
	}, [match.params.ts]);

	React.useEffect(() => {
		pagination["searchWord"] = '';
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		// let owner = api.getUserInfo();
		const smartRoutine = wsData.smartRoutineList;
		setSmartRoutine(smartRoutine);
		setTotalCount(wsData.smartRoutineListTotal);
	}, [wsData]);

	const setRefresh = (data) => {
		const {isRefresh, page} = data
		setSearchData(data);
		setIsRefresh(isRefresh);
		setCurrentPage(page);
	};

	const getCurrentPage = (page) => {
		setCurrentPage(page);
	};

	const deleteSmartRoutine = async elm => {
		await api.smartRoutineCUD({isDel: true, routineId: elm['routine_id']}).then(res => {
			onSearch({page: currentPage}).catch();
		});
	};

	const tableColumns = [
		{
			title: '정류장 이름',
			dataIndex: 'house_name',
			width: 300,
			sorter: true,
		},
		{
			title: '운행방향',
			dataIndex: 'bus_direction',
			width: 250,
			sorter: true,
		},
		{
			title: '루틴 이름',
			dataIndex: 'routine_name',
			width: 250,
			sorter: true,
		},
		{
			title: '만든 날짜',
			dataIndex: 'created',
			width: 200,
			render: (_, record) => (
				<div className="d-flex">
					{util.formatDTS(record['created'])}
				</div>
			),
			sorter: true,
		},
		{
			title: '마지막 실행',
			dataIndex: 'last_run',
			width: 200,
			render: (_, record) => (
				<div className="d-flex">
					{util.formatDTS(record['last_run'])}
				</div>
			),
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 100,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			),
			sorter: true,
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="View">
						<SmartRoutineFormInDrawer title={labels.SMART_ROUTINE_EDIT} elm={elm} onSearch={onSearch}/>
					</Tooltip>
					<Tooltip title="Delete">
						<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
							deleteSmartRoutine(elm).catch();
						}} okText={labels.YES} cancelText={labels.NO}>
							<Button danger icon={<DeleteOutlined/>} size="small"/>
						</Popconfirm>
					</Tooltip>
				</div>
			)
		}
	];
	return (
		<div>
			<DataTableView
				columns={tableColumns}
				dataSource={smartRoutine}
				rowKey='id'
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				isRefresh={isRefresh}
				setRefresh={setRefresh}
				title={labels.SMART_ROUTINE_ADD}
			/>
		</div>
	);
};

export default SmartRoutine;
