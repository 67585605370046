import React from 'react';
import {api} from "../../services/service";
import {useSelector} from "react-redux";
import {pagination} from "../../utils/helper";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import AvatarStatus from "../../components/shared-components/AvatarStatus";
import {Button, Popconfirm, Tag, Tooltip} from "antd";
import HouseFormInDrawer from "../../components/uplink-components/HouseFormInDrawer";
import {labels} from "../Labels";
import {DeleteOutlined} from "@ant-design/icons";
import DataTableView from "../../components/uplink-components/DataTableView";
import util from "../../utils";

export const House = () => {
	const [owner, setOwner] = React.useState(api.getUserInfo()); // 현재 사용자
	const [houseList, setHouseList] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [currentPage, setCurrentPage] = React.useState(1);
	const wsData = useSelector(state => state.ws);

	const onSearch = async (searchInfo) => {
		const tableInfo = {...pagination, page: currentPage, ...searchInfo};
		await api.getHouseList(tableInfo);
	};

	React.useEffect(() => {
		pagination["searchWord"] = '';
		onSearch().catch();
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		setHouseList(wsData.houseList);
		setTotalCount(wsData.houseListTotal);
	}, [wsData]);

	const getCurrentPage = (page) => {
		setCurrentPage(page);
	};

	const deleteDeviceType = async elm => {
		// console.log(elm['house_id'])
		await api.houseCUD({houseId: elm['house_id'], isDel: true}).then(res => {
			onSearch({page: currentPage}).catch();
		});
	};

	const tableColumns = [
		{
			title: '브랜치 이름',
			dataIndex: 'branch_name',
			width: 200,
			sorter: true,
		},
		{
			title: '정류장 이름',
			dataIndex: 'house_name',
			width: 250,
			sorter: true,
		},
		{
			title: '운행방향',
			dataIndex: 'bus_direction',
			width: 250,
			sorter: true,
		},
		{
			title: '만든 날짜',
			dataIndex: 'created',
			width: 200,
			render: (_, record) => (
				<div className="d-flex">
					{util.formatDTS(record['created'])}
				</div>
			),
			sorter: true,
		},
		{
			title: '정보',
			width: 450,
			dataIndex: 'remark',
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 100,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			),
			sorter: true,
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="View">
						<HouseFormInDrawer title={labels.HOUSE_EDIT} elm={elm} onSearch={onSearch}/>
					</Tooltip>
					<Tooltip title="Delete">
						<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
							deleteDeviceType(elm).catch();
						}} okText={labels.YES} cancelText={labels.NO}>
							<Button danger icon={<DeleteOutlined/>} size="small"/>
						</Popconfirm>
					</Tooltip>
				</div>
			)
		}
	];
	return (
		<div>
			<DataTableView
				columns={tableColumns}
				dataSource={houseList}
				rowKey='id'
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				title={labels.HOUSE_ADD}
			/>
		</div>
	);
};

export default House;
