import React from 'react'
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import {useSelector} from "react-redux";
import {api} from "../../services/service";
import DeviceTypeFormInDrawer from "../../components/uplink-components/DeviceTypeFormInDrawer";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";

export const DeviceType = () => {
	const [owner, setOwner] = React.useState(api.getUserInfo()); // 현재 사용자
	const [deviceTypeList, setDeviceTypeList] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [currentPage, setCurrentPage] = React.useState(1);
	const wsData = useSelector(state => state.ws);

	const onSearch = async (searchInfo) => {
		const tableInfo = {...pagination, page: currentPage, ...searchInfo};
		setTimeout(() => {
			api.getDeviceTypeList(tableInfo);
		}, 0);
	};

	React.useEffect(() => {
		pagination["searchWord"] = '';
		onSearch().catch();
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		let typeList = [];
		const deviceTypeList = wsData.deviceTypeList;
		if(deviceTypeList) {
			for (let key of deviceTypeList) {
				typeList.push(key);
			}
		}
		setDeviceTypeList(typeList);
		setTotalCount(wsData.deviceTypeListTotal);
	}, [wsData]);

	const getCurrentPage = (page) => {
		setCurrentPage(page);
	};

	const deleteDeviceType = async elm => {
		await api.deviceTypeCUD({deviceTypeId: elm["device_type_id"], isDel: true}).then(res => {
			onSearch({page: currentPage}).catch();
		});
	};

	const tableColumns = [
		{
			title: '타입 이름',
			dataIndex: 'device_type_name',
			width: 400,
			sorter: true,
		},
		{
			title: '메이커',
			dataIndex: 'maker',
			width: 400,
			sorter: true,
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 400,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			),
			sorter: true,
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="View">
						<DeviceTypeFormInDrawer title={labels.DEVICE_TYPE_EDIT} elm={elm} onSearch={onSearch}/>
					</Tooltip>
					<Tooltip title="Delete">
						<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
							deleteDeviceType(elm).catch();
						}} okText={labels.YES} cancelText={labels.NO}>
							<Button danger icon={<DeleteOutlined/>} size="small"/>
						</Popconfirm>
					</Tooltip>
				</div>
			)
		}
	];
	return (
		<div>
			<DataTableView
				columns={tableColumns}
				dataSource={deviceTypeList}
				rowKey='id'
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				title={labels.DEVICE_TYPE_ADD}
			/>
		</div>
	);
};

export default DeviceType
