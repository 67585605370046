import React from 'react';
import {api} from "../../services/service";
import {useSelector} from "react-redux";
import {pagination} from "../../utils/helper";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import {Button, Popconfirm, Tag, Tooltip} from "antd";
import BranchFormInDrawer from "../../components/uplink-components/BranchFormInDrawer";
import {labels} from "../Labels";
import {DeleteOutlined} from "@ant-design/icons";
import DataTableView from "../../components/uplink-components/DataTableView";
import util from "../../utils";

export const DID = () => {
	const [dataList, setDataList] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [currentPage, setCurrentPage] = React.useState(1);
	const wsData = useSelector(state => state.ws);

	const onSearch = async (searchInfo) => {
		const tableInfo = {...pagination, ...searchInfo};
		// await api.getBranchTypes();
		setTimeout(() => {
			api.getDidInfoList(tableInfo);
		}, 0);
	};

	React.useEffect(() => {
		pagination["searchWord"] = '';
		onSearch().catch();
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		setDataList(wsData.didInfoList);
		setTotalCount(wsData.didInfoListTotal);
	}, [wsData]);

	const getCurrentPage = (page) => {
		setCurrentPage(page);
	};

	const deleteDeviceType = async elm => {
		await api.setDidInfo({apiKey: elm["api_key"], isDel: true}).then(res => {
			onSearch({page: currentPage}).catch();
		});
	};

	const tableColumns = [
		{
			title: '디바이스 이름',
			dataIndex: 'device_name',
			width: 250,
			sorter: true,
		},
		{
			title: '하우스 이름',
			dataIndex: 'house_name',
			width: 250,
			sorter: true,
		},
		{
			title: '브랜치 이름',
			dataIndex: 'branch_name',
			width: 250,
			sorter: true,
		},
		{
			title: 'API 키',
			dataIndex: 'api_key',
			width: 200,
			sorter: true,
		},
		{
			title: '아이템 타입',
			dataIndex: 'item_types',
			width: 300,
			sorter: true,
		},
		{
			title: '만든 날짜',
			dataIndex: 'created',
			width: 250,
			render: (_, record) => (
				<div className="d-flex">
					{util.formatDTS(record['created'])}
				</div>
			),
			sorter: true,
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="Delete">
						<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
							deleteDeviceType(elm).catch();
						}} okText={labels.YES} cancelText={labels.NO}>
							<Button danger icon={<DeleteOutlined/>} size="small"/>
						</Popconfirm>
					</Tooltip>
				</div>
			)
		}
	];
	return (
		<div>
			<DataTableView
				columns={tableColumns}
				dataSource={dataList}
				rowKey='id'
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				title={labels.DID_ADD}
			/>
		</div>
	);
};

export default DID;
