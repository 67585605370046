import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Table, Popconfirm} from 'antd';
import {PlusOutlined, SettingOutlined, DeleteOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';

const DeviceTypeSelectInDrawer = ({title, elm, getSensorData, getCommandData}) => {
	const [id, setId] = React.useState(0);
	const [sensorType, setSensorType] = React.useState();
	const [sensorName, setSensorName] = React.useState();
	const [sensorUnit, setSensorUnit] = React.useState();
	const [sensorGline, setSensorGline] = React.useState([]);
	const [sensorGood, setSensorGood] = React.useState();
	const [sensorNormal, setSensorNormal] = React.useState();
	const [sensorBad, setSensorBad] = React.useState();
	const [commandType, setCommandType] = React.useState();
	const [commandName, setCommandName] = React.useState();
	const [command, setCommand] = React.useState([]);
	const [visible, setVisible] = React.useState(false);
	const [inputData, setInputData] = React.useState({});
	const [editData, setEditData] = React.useState();
	const [type, setType] = React.useState();
	const [value, setValue] = React.useState();
	const [buttonTitle, setButtonTitle] = React.useState('추가');
	const [buttonSize, setButtonSize] = React.useState(title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ? 'small' : null);
	const [form] = Form.useForm();

	const showDrawer = (elm) => {
		resetFields();
		setVisible(true);
		if (elm && title === labels.SENSOR_EDIT) {
			const {id, sensorType, sensorName, sensorUnit, sensorOffset, sensorGline = ['', '', '']} = elm;
			setId(id);
			setSensorType(sensorType);
			setSensorName(sensorName);
			setSensorUnit(sensorUnit);
			setSensorGline(sensorGline);
			setSensorGood(sensorGline[0]);
			setSensorNormal(sensorGline[1]);
			setSensorBad(sensorGline[2]);
			setInputData({
				...inputData,
				id,
				sensorType,
				sensorName,
				sensorUnit,
				sensorGline,
			});
		} else if (elm && title === labels.COMMAND_EDIT) {
			let commandInfo = [];
			const {id, type, name, command = {}} = elm;
			setId(id);
			setCommandType(type);
			setCommandName(name);
			setInputData({...inputData, id, commandType: type, commandName: name, command});
			if (command.length > 0) {
				commandInfo = command?.map((com, i) => {
					return (
						{...com, id: i + 1}
					);
				});
			}
			setCommand(commandInfo);
		}
	};

	const onClose = () => {
		setVisible(false);
		setButtonTitle('추가');
		resetFields();
	};

	const onSubmit = () => {
		setVisible(false);
		if (title === labels.SENSOR_ADD || title === labels.SENSOR_EDIT) {
			const gline = sensorGood || sensorNormal || sensorBad ? [sensorGood??0, sensorNormal??0, sensorBad??0] : undefined;
			getSensorData({...inputData, sensorGline: gline});
		} else if (title === labels.COMMAND_ADD || title === labels.COMMAND_EDIT) {
			getCommandData(inputData);
		}
		resetFields();
	}

	const resetFields = () => {
		form.resetFields();
		setId(undefined);
		setSensorType(undefined);
		setSensorName(undefined);
		setSensorUnit(undefined);
		setSensorGline(undefined);
		setSensorGood(undefined);
		setSensorNormal(undefined);
		setSensorBad(undefined);
		setInputData(undefined);
		setType(undefined);
		setValue(undefined);
	};

	const onEditCommand = (elm, title) => {
		setButtonTitle(title);
		setEditData(elm);
		setType(elm.type);
		setValue(elm.value);
		form.setFieldsValue({
			type: elm.type,
			value: elm.value
		});
	};

	const deleteCommand = elm => {
		let commandInfo = [];
		let commandData = command;
		let index = -1;
		for (let key of commandData) {
			index++;
			if (key["id"] === elm["id"]) {
				commandData.splice(index, 1)
			}
		}
		commandInfo = commandData.map((data, i) => {
			return (
				{...data, id: data.id = i}
			);
		})
		setCommand(commandInfo);
		setInputData({...inputData, command: commandInfo});
		form.resetFields();
	}

	const handleInputData = e => {
		const name = e.target.id;
		const value = e.target.value;

		if(name === 'sensorGood') setSensorGood(value);
		if(name === 'sensorNormal') setSensorNormal(value);
		if(name === 'sensorBad') setSensorBad(value);

		setInputData({...inputData, [name]: value});
	}

	const buttonStyle = title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ?
		{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'} :
		{display: 'flex', justifyContent: 'flex-end', marginTop: '12px', marginRight: '8px'};

	const onAdd = () => {
		const dataInfo = {id: command.length+1, type: inputData.type, value: inputData.value};
		if(editData) {
			command.filter(x => x.id === editData.id).map(com => {
				com.type = inputData.type;
				com.value = inputData.value;
			});
			setCommand([...command]);
			setInputData({...inputData, command: [...command]});
		} else {
			setCommand([...command, dataInfo]);
			setInputData({...inputData, command: [...command, dataInfo]});
		}
		form.resetFields();
	};

	const tableColumns = [
		{
			title: '타입',
			dataIndex: 'type',
			width: '40%',
		},
		{
			title: '값',
			dataIndex: 'value',
			width: '40%',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
						deleteCommand(elm)
					}} okText={labels.YES} cancelText={labels.NO}>
						<Button danger icon={<DeleteOutlined/>} size="small"/>
					</Popconfirm>
				</div>
			)
		}
	];

	return (
		<div style={buttonStyle}>
			<Button type="primary"
			        icon={title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ? <SettingOutlined/> :
				        <PlusOutlined/>}
			        onClick={() => showDrawer(elm)} size={buttonSize}>
				{title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ? undefined : title}
			</Button>
			<Drawer
				title={title}
				width={window.innerWidth > 900 ? 720 : window.innerWidth}
				onClose={() => onClose(elm)}
				visible={visible}
				bodyStyle={{paddingBottom: 80}}
			>
				{title === labels.SENSOR_ADD || title === labels.SENSOR_EDIT ?
					<Form layout="vertical" hideRequiredMark form={form}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="sensorType"
									label="타입"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={sensorType} placeholder={'센싱 타입을 선택하세요'}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="sensorName"
									label="이름"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={sensorName} placeholder="센싱 이름을 작성하세요"/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="sensorUnit"
									label="유닛"
									getValueFromEvent={e => handleInputData(e, 'sensorUnit')}
								>
									<Input defaultValue={sensorUnit} required={true} placeholder="유닛을 작성하세요"/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={8}>
								<Form.Item
									name="sensorGood"
									label="좋음"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={sensorGood} placeholder={'좋음 값을 작성하세요'}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="sensorNormal"
									label="보통"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={sensorNormal} placeholder="보통 값을 작성하세요"/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="sensorBad"
									label="나쁨"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={sensorBad} placeholder="나쁨 값을 작성하세요"/>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							style={{
								textAlign: 'center',
								marginTop: '50px'
							}}
						>
							<Button
								onClick={() => onClose(elm)}
								style={{marginRight: 8}}
							>
								취소
							</Button>
							<Button onClick={onSubmit} type="primary">
								{title === labels.SENSOR_EDIT ? '수정' : '추가'}
							</Button>
						</Form.Item>
					</Form> : undefined}
				{/* TODO --------------------------------------------------Under 명령어 Form----------------------------------------------------*/}
				{title === labels.COMMAND_ADD || title === labels.COMMAND_EDIT ?
					<Form layout="vertical" hideRequiredMark form={form}>
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name="commandType"
									label="타입"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={inputData?.commandType} placeholder={'명령어 타입을 작성하세요'}/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="commandName"
									label="이름"
									getValueFromEvent={e => handleInputData(e)}
								>
									<Input defaultValue={inputData?.commandName} placeholder="명령어 이름을 작성하세요"/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name="jcommand"
									label="값"
									initialValue={command}
									getValueFromEvent={e => handleInputData(e)}
								>
									<Table
										columns={tableColumns}
										dataSource={command}
										rowKey='id'
										bordered
										pagination={false}
										scroll={{ y: 430 }}
										onRow={(record) => {
											return {
												onClick: (e) => {
													onEditCommand(record, '수정');
												}
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={9}>
								<Form.Item name="type" label="" getValueFromEvent={e => handleInputData(e)}>
									<Input defaultValue={type} placeholder="타입"/>
								</Form.Item>
							</Col>
							<Col span={9}>
								<Form.Item name="value" label="" getValueFromEvent={e => handleInputData(e)}>
									<Input defaultValue={value} placeholder="값"/>
								</Form.Item>
							</Col>
							<Col span={2}>
								<Button onClick={onAdd} size={buttonSize} style={{marginRight: 10, marginBottom: 20}}>{buttonTitle}</Button>
							</Col>
						</Row>
						<Form.Item
							style={{
								textAlign: 'center',
								marginTop: '50px'
							}}
						>
							<Button
								onClick={() => onClose(elm)}
								style={{marginRight: 8}}
							>
								취소
							</Button>
							<Button onClick={onSubmit} type="primary">
									{title === labels.COMMAND_EDIT ? '저장' : '추가'}
							</Button>
						</Form.Item>
					</Form> : undefined}
			</Drawer>
		</div>
	);
}

export default DeviceTypeSelectInDrawer;
