import React from 'react'
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import DeviceFormInDrawer from "../../components/uplink-components/DeviceFormInDrawer";
import {useSelector} from "react-redux";
import {api} from "../../services/service";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import DeviceViewFormInDrawer from "../../components/uplink-components/DeviceViewFormInDrawer";
import util from "../../utils";

export const Device = ({match}) => {
	const [deviceList, setDeviceList] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [searchData, setSearchData] = React.useState();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [isRefresh, setIsRefresh] = React.useState(false);
	const currentPageRef = React.useRef(1);
	const wsData = useSelector(state => state.ws);

	const onSearch = async (searchInfo) => {
		setSearchData(searchInfo);
		const tableInfo = {...pagination, page: currentPage, ...searchInfo};
		await api.getDeviceList(tableInfo);
	};
	React.useEffect(() => {
		if(match.params.ts) {
			setIsRefresh(true);
		}
		onSearch({page: 1, searchWord: searchData?.searchWord}).catch();
	}, [match.params.ts]);

	React.useEffect(() => {
		pagination["searchWord"] = '';
		store.dispatch(toggleHeaderDatepicker(false));
		store.dispatch(filterBadgeCounter(0));
	}, []);

	React.useEffect(() => {
		const deviceList = wsData.deviceList;
		let list = [];
		if(deviceList) {
			for (let key of deviceList) {
				list.push(key);
			}
		}
		setDeviceList(list);
		setTotalCount(wsData.deviceListTotal);
	}, [wsData]);

	const setRefresh = (data) => {
		const {isRefresh, page} = data
		setSearchData(data);
		setIsRefresh(isRefresh);
		setCurrentPage(page);
	};

	const getCurrentPage = (page) => {
		currentPageRef.current = page;
		setCurrentPage(page);
	};

	const deleteDevice = async elm => {
		await api.deviceCUD({deviceId: elm["device_id"], isDel: true}).then(res => {
			onSearch({page: currentPage}).catch();
		});
	};

	const tableColumns = [
		{
			title: '브랜치 이름',
			dataIndex: 'branch_name',
			width: 200,
			sorter: true,
		},
		{
			title: '정류장 이름',
			dataIndex: 'house_name',
			width: 280,
			sorter: true,
		},
		{
			title: '운행방향',
			dataIndex: 'bus_direction',
			width: 250,
			sorter: true,
		},
		{
			title: '디바이스 이름',
			dataIndex: 'device_name',
			width: 220,
			sorter: true,
		},
		{
			title: '디바이스 타입',
			dataIndex: 'device_type_name',
			width: 200,
			sorter: true,
		},
		{
			title: '시리얼 넘버',
			dataIndex: 'serial',
			width: 150,
		},
		{
			title: '활성화',
			dataIndex: 'isactivated',
			width: 60,
			render: status => (
				<Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
					{(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
				</Tag>
			),
			sorter: true,
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
					<Tooltip title="View">
						<DeviceViewFormInDrawer title={labels.DEVICE_VIEW} elm={elm} houseList={''} onSearch={onSearch}/>
					</Tooltip>
					<Tooltip title="View">
						<DeviceFormInDrawer title={labels.DEVICE_EDIT} elm={elm} houseList={''} onSearch={onSearch} page={currentPageRef.current}/>
					</Tooltip>
					<Tooltip title="Delete">
						<div style={{float: 'left'}}>
							<Popconfirm placement="leftTop" title={labels.DELETE_TITLE} onConfirm={() => {
								deleteDevice(elm).catch();
							}} okText={labels.YES} cancelText={labels.NO}>
								<Button danger icon={<DeleteOutlined/>} size="small"/>
							</Popconfirm>
						</div>
					</Tooltip>
				</div>
			)
		}
	];
	return (
		<div>
			{/*<div style={{position: 'absolute', top: 25, right: 20}}>*/}
			{/*	<DeviceFormInDrawer title={labels.DEVICE_ADD} onSearch={onSearch}/>*/}
			{/*</div>*/}
			<DataTableView
				columns={tableColumns}
				dataSource={deviceList}
				rowKey='id'
				totalCount={totalCount}
				onSearch={onSearch}
				getCurrentPage={getCurrentPage}
				showSearchWord={true}
				isRefresh={isRefresh}
				setRefresh={setRefresh}
				title={labels.DEVICE_ADD}
			/>
		</div>
	);
};

export default Device
